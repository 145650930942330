import getAudioInputDevicesChangeHandlerDefault from './audioInputDevicesChangeHandler';
import getNativeMediaDevicesFactoryDefault from '../helpers/deviceHelpers';
import EventsFactory from './events';

export default (deps = {}) => {
  const getAudioInputDevicesChangeHandler =
    deps.getAudioInputDevicesChangeHandler || getAudioInputDevicesChangeHandlerDefault;
  const getNativeMediaDevicesFactory =
    deps.getNativeMediaDevicesFactory || getNativeMediaDevicesFactoryDefault;

  const audioInputDevicesChangeHandler = getAudioInputDevicesChangeHandler();
  const {
    getNativeMediaDevices,
  } = getNativeMediaDevicesFactory();
  const Events = EventsFactory();

  const audioInputDevicesChangeListener = (publisher) => {
    let isProcessing = false;
    const deviceChangeListener = async () => {
      if (isProcessing) {
        return;
      }
      isProcessing = true;
      const device = await audioInputDevicesChangeHandler();
      isProcessing = false;
      await publisher.setAudioSource(device.deviceId);
      publisher.dispatchEvent(Events.AudioInputDeviceChangedEvent(device));
    };
    // In order to keep the current device ID synchronized, let's listen to the device change event
    const mediaDevices = getNativeMediaDevices();
    mediaDevices.addEventListener('devicechange', deviceChangeListener);

    const removeAudioInputDevicesChangeListener = () => {
      mediaDevices.removeEventListener('devicechange', deviceChangeListener);
    };

    return removeAudioInputDevicesChangeListener;
  };

  return audioInputDevicesChangeListener;
};
