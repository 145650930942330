// @ts-ignore
import { workerize, Workerized } from "@vonage/js-workerizer";
import {
    ErrorData,
    EventDataMap,
    getVonageMetadata,
    MediaProcessorInterface,
    PipelineInfoData,
    WarnData,
} from "@vonage/media-processor";
import Emittery from "emittery";
import { BackgroundOptions, MediaProcessorConfig } from "../../main";
import VonageAssetsLoaderHelper from "../utils/assets-loader";
import { FlickeringOptions } from "../renderers/webgl/pipelines/improve-segmentation-mask";
import { ResolvedWebglQuery } from "../renderers/webgl/webgl-profiler";
import { ProcessorWorker } from "./processor-worker";
import Worker from "./processor-worker?worker&inline";

export enum WorkerOperations {
    init = "init",
    transform = "transform",
    destroy = "destroy",
    setBackgroundOptions = "setBackgroundOptions",
    setVideoBGReadable = "setVideoBGReadable",
    setVirtualBGImage = "setVirtualBGImage",
    setTrackExpectedRate = "setTrackExpectedRate",
}

export interface InnerEventDataMap {
    initResults: string;
    transformResults: string;
    destroyResults: string;
    setBackgroundOptionsResults: string;
    setVideoBGReadableResults: string;
    setVirtualBGImageResults: string;
}

export class ProcessorMain
    extends Emittery<EventDataMap>
    implements MediaProcessorInterface
{
    private rate = 30;
    private worker?: Workerized<ProcessorWorker>;
    private static initCount: number = 0;
    private config: MediaProcessorConfig;
    private backgroundOptions?: BackgroundOptions;
    private isEnabled = true;

    constructor(config: MediaProcessorConfig) {
        super();
        this.config = config;
    }

    public async setTrackExpectedRate(rate: number) {
        await this.startWorker();
        await this.worker?.setTrackExpectedRate(rate);
    }

    public async setBackgroundOptions(options: BackgroundOptions) {
        this.backgroundOptions = options;
        await this.updateBackgroundOptions(options);
    }

    public async transform(
        readable: ReadableStream<any>,
        writable: WritableStream<any>
    ): Promise<void> {
        await this.startWorker();
        await this.worker?.transform(readable, writable);
    }

    public async destroy(): Promise<void> {
        await this.worker?.terminate();
        this.worker = undefined;
    }

    public async profile(duration: number): Promise<ResolvedWebglQuery[]> {
        const response = await this.worker?.profile(duration);
        return JSON.parse(response ?? "[]");
    }

    public async enable() {
        this.isEnabled = true;
        await this.worker?.enable();
    }

    public async disable() {
        this.isEnabled = false;
        await this.worker?.disable();
    }

    private async listenWorker() {
        while (this.worker?.workerContext.worker) {
            const { name, data } = await this.worker?.onMediaProcessorEvent();
            switch (name) {
                case "error":
                case "warn":
                    this.emit(name, data as WarnData | ErrorData);
                    break;

                case "pipelineInfo":
                    this.emit(name, data as PipelineInfoData);
                    switch (data) {
                        case "pipeline_ended":
                        case "pipeline_ended_with_error":
                            await this.destroy();
                            return;
                    }
                    break;
            }
        }
    }

    private async startWorker() {
        if (!this.config || this.worker) {
            return;
        }
        this.worker = await workerize<ProcessorWorker>(ProcessorWorker, Worker);
        if (!this.isEnabled) {
            await this.worker.disable();
        }
        await this.worker.init(
            `worker_${ProcessorMain.initCount++}`,
            this.config
        );
        if (this.backgroundOptions) {
            await this.updateBackgroundOptions(this.backgroundOptions);
        }
        await this.worker.setVonageMetadata(getVonageMetadata());
        await this.setBackgroundOptions(this.config);
        this.listenWorker();
    }

    private async updateBackgroundOptions(options: BackgroundOptions) {
        await this.startWorker();

        const { transformerType } = options;
        switch (transformerType) {
            case "VideoBackground":
                await this.worker?.setVideoBGReadable(
                    await VonageAssetsLoaderHelper.createVideoReadable(
                        options.backgroundAssetUri
                    )
                );
                break;
            case "VirtualBackground":
                await this.worker?.setVirtualBGImage(
                    await VonageAssetsLoaderHelper.createImageCanvas(
                        options.backgroundAssetUri
                    )
                );
                break;
        }

        await this.worker?.setBackgroundOptions(options);
    }
}
