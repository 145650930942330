import { WebglPipelineProgram } from "../../webgl-pipeline-program";
import fragmentShader from "./render-texture.frag?raw";
import vertexShader from "./render-texture.vert?raw";

export class RenderTexture extends WebglPipelineProgram {
    protected getFragmentShader(): string {
        return fragmentShader;
    }
    protected getVertexShader(): string {
        return vertexShader;
    }
}
