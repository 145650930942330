import {
    Options as FaceDetectionOptions,
    Results as FaceDetectionResults,
} from "@mediapipe/face_detection";
import {
    Options as FaceMeshOptions,
    Results as FaceMeshResults,
} from "@mediapipe/face_mesh";
import {
    Options as HandsOptions,
    Results as HandsResults,
} from "@mediapipe/hands";
import {
    Options as HolisticOptions,
    Results as HolisticResults,
} from "@mediapipe/holistic";
import {
    Options as ObjectronOptions,
    Results as ObjectronResults,
} from "@mediapipe/objectron";
import {
    Options as PoseOptions,
    Results as PoseResults,
} from "@mediapipe/pose";
import {
    Options as SelfieSegmentationOptions,
    Results as SelfieSegmentationResults,
} from "@mediapipe/selfie_segmentation";
import { TextureFunc } from "twgl.js";

/**
 * BlurRadius specifies how much bluring filter to apply by a given transformer.
 */
export enum BlurRadius {
    /**
     * Low bluring filtering (5px).
     */
    Low = "Low",

    /**
     * High bluring filtering (10px).
     */
    High = "High",
}

/**
 * Type of transformer to instantiate
 */
export enum BackgroundTransformerType {
    /**
     * Background blur
     */
    BackgroundBlur = "BackgroundBlur",

    /**
     * Background replace with an image
     */
    VirtualBackground = "VirtualBackground",

    /**
     * Background replace with an video
     */
    VideoBackground = "VideoBackground",

    /**
     * Silhouette blur
     */
    SilhouetteBlur = "SilhouetteBlur",
}

/**
 * MediaProcessorBaseConfig specifies the absolute URL for the path where the mediapipe assets used by the transformers from this library are stored.
 * Vonage hosts static assets of mediapipe that will be used in case this property is not set.
 * Vonage promises to take care of maintaining all versioning.
 * **WARNING**. By using this option the developer takes responsibility of the assets and their versioning.
 * *mediapipe selfie segmintaion got those assets:*
 * *selfie_segmentation_landscape.tflite*
 * *selfie_segmentation_solution_simd_wasm_bin.js*
 * *selfie_segmentation_solution_simd_wasm_bin.wasm*
 * *selfie_segmentation_solution_wasm_bin.js*
 * *selfie_segmentation_solution_wasm_bin.wasm*
 * *selfie_segmentation.binarypb*
 * *selfie_segmentation.tflite*
 * Please keep in mind that the assets list can change between versions.
 * All assets can be taken from `node_modules/@mediapipe/selfie_segmentation`
 */
export interface MediaProcessorBaseConfig {
    /**
     * Type of transformer
     */
    transformerType: BackgroundTransformerType;

    /**
     * A uri path to mediapipe assets.
     */
    mediapipeBaseAssetsUri?: string;
    modelAssetUriPath?: string;

    renderingOptions?: RenderingOptions;
}

export type RenderingOptions = Canvas2dOptions | WebglOptions;

/**
 * AssetBackgroundConfig specifies the absolute URL to the image or video file used for background replacement.
 */
export interface AssetBackgroundConfig {
    /**
     * URI for image or video asset.
     */
    backgroundAssetUri: string;
}

/**
 * BackgroundBlurConfig specifies the configuration for a transformer logic that performs background blurring.
 */
export interface BackgroundBlurConfig extends MediaProcessorBaseConfig {
    /**
     * Type of post process.
     */
    transformerType: BackgroundTransformerType.BackgroundBlur;
    /**
     * Blur strength, in px if provided as number
     */
    radius?: BlurRadius | number;
}

/**
 * VirtualBackgroundConfig specifies the configuration for a transformer logic that performs background replacement with a given image.
 */
export interface VirtualBackgroundConfig
    extends AssetBackgroundConfig,
        MediaProcessorBaseConfig {
    /**
     * Type of post process.
     */
    transformerType: BackgroundTransformerType.VirtualBackground;
}

/**
 * VirtualBackgroundConfig specifies the configuration for a transformer logic that performs background replacement with a given video.
 */
export interface VideoBackgroundConfig
    extends AssetBackgroundConfig,
        MediaProcessorBaseConfig {
    /**
     * Type of post process.
     */
    transformerType: BackgroundTransformerType.VideoBackground;
}

/**
 * SilhouetteBlurConfig specifies the configuration for a transformer logic that performs siluete blurring.
 */
export interface SilhouetteBlurConfig extends MediaProcessorBaseConfig {
    /**
     * Type of post process.
     */
    transformerType: BackgroundTransformerType.SilhouetteBlur;
    /**
     * Blur strength, in px if provided as number
     */
    radius?: BlurRadius | number;
}

/**
 * BackgroundOptions background options
 */
export type BackgroundOptions =
    | BackgroundBlurConfig
    | VirtualBackgroundConfig
    | VideoBackgroundConfig
    | SilhouetteBlurConfig;

export type {
    FaceDetectionResults,
    FaceDetectionOptions,
    FaceMeshResults,
    FaceMeshOptions,
    HandsResults,
    HandsOptions,
    HolisticResults,
    HolisticOptions,
    ObjectronResults,
    ObjectronOptions,
    SelfieSegmentationResults,
    SelfieSegmentationOptions,
    PoseResults,
    PoseOptions,
};

/**
 * MediaPipeResults types of results object of MediaPipe
 */
export type MediaPipeResults =
    | FaceDetectionResults
    | FaceMeshResults
    | HandsResults
    | HolisticResults
    | ObjectronResults
    | SelfieSegmentationResults
    | PoseResults;
/**
 * ResultsListener callback function from MediaPipe process
 * @results - The results object from MediaPipe
 * @returns - can return a promise of void
 */
export type MediaPipeResultsListener = <T extends MediaPipeResults>(
    results: T
) => Promise<void> | void;

/**
 * MediaPipeModelType supported models types
 */
export type MediaPipeModelType =
    | "face_mesh"
    | "face_detection"
    | "hands"
    | "holistic"
    | "objectron"
    | "selfie_segmentation"
    | "pose";

/**
 * defines one mediapipe model config
 * @modelType - which model is required
 * @listener - callback function from the model
 * @options - define options for the mediapipe model that is used. for more info check https://google.github.io/mediapipe/getting_started/javascript.html
 * @assetsUri (optional) - can be set to get wasm/tflite/js/binarypb assets. Vonage provides static assets.
 * *** WARNING *** - using this option moves the responsibility of the assets and the versioning to the user.
 * please keep in mind that the assets list can change between versions!
 * assets are different between mediapipe models.
 */
export type MediaPipeModelConfig = {
    modelType: MediaPipeModelType;
    listener: MediaPipeResultsListener;
    options:
        | FaceDetectionOptions
        | FaceMeshOptions
        | HandsOptions
        | HolisticOptions
        | ObjectronOptions
        | SelfieSegmentationOptions
        | PoseOptions;
    assetsUri?: string;
};

/**
 * MediapipeConfig specified config of mediapipe helper:
 * this structure allows the user to create few mediapipe models that will run in parallel.
 * @modelTypesArray - array of mediapipe models to be loaded and called.
 */
export type MediapipeConfig = {
    mediaPipeModelConfigArray: Array<MediaPipeModelConfig>;
};

/**
 * Vector containing 3 numbers
 * @internal
 */
export type vec3 = [number, number, number];

/**
 * Vector containing 4 numbers
 * @internal
 */
export type vec4 = [number, number, number, number];

/**
 * Valid data to feed webgl texture
 * @internal
 */
export type TextureSource =
    | string
    | number[]
    | ArrayBufferView
    | TexImageSource
    | TexImageSource[]
    | string[]
    | TextureFunc
    | undefined;

/**
 * Valid uniform data map
 * @internal
 */
export type UniformDataMap = {
    [key: string]: number | number[] | WebGLTexture;
};

/**
 * Feature support detail
 */
export interface FeatureSupport {
    /**
     * Is the feature supported
     */
    supported: boolean;

    /**
     * Readable message explaining the feature is not supported
     */
    message?: string;
}

/**
 * Features supported by the browser
 */
export interface SupportedBrowserFeatures {
    /**
     * Web assembly support, required to use ml-transformer
     */
    wasm: FeatureSupport;

    /**
     * Simd support, required to use ml-transformer
     */
    simd: FeatureSupport;

    /**
     * Insertable stream support, required to use ml-transformer
     */
    insertableStreams: FeatureSupport;

    /**
     * GPU processing support, optional
     */
    gpu: FeatureSupport;

    /**
     * Webgl context support, required
     */
    webgl: FeatureSupport;

    /**
     * Hardware support (whether the hardware underneath is powerful enough to run the library efficiently), optional.
     * If this flag is false, the cpu/gpu could end up overloaded producing lag/glitches.
     */
    performance: FeatureSupport;
}

/**
 * Selfie segmentation algorithm type
 * Fast can flickering but has low impact on performance
 * Precise avoid glitches and flickering, but is most costly.
 *
 * More explanations could be find here: https://vonage.github.io/ml-transformers-docs/docs/post-processing
 */
export enum WebglSelfieSegmentationType {
    /**
     * No selfie segmentation post-processing. It might produce flickering and has low impact on performance.
     */
    FAST = "FAST",
    /**
     * Enable selfie segmentation post-processing. It reduces flickering and glitches, however it might have some impact on performance.
     */
    PRECISE = "PRECISE",
}

export enum RenderingType {
    CANVAS = "CANVAS",
    WEBGL = "WEBGL",
}

export interface Canvas2dOptions {
    type: RenderingType.CANVAS;
}

export interface WebglOptions {
    type: RenderingType.WEBGL;
    selfieSegmentationType?: WebglSelfieSegmentationType;
}
