import { BackgroundImagePipeline } from "./background-image";

export class BackgroundVideoPipeline extends BackgroundImagePipeline {
    private frameReader?: ReadableStreamDefaultReader<VideoFrame>;
    public preciseMaskEnabled: boolean = true;

    public set reader(reader: ReadableStreamDefaultReader<VideoFrame>) {
        this.frameReader = reader;
    }

    public async run() {
        if (this.frameReader) {
            await this.readNextVideoFrame();
            await super.run();
        }
    }

    private async readNextVideoFrame() {
        const result = await this.frameReader?.read();
        if (result?.value) {
            this.inputBackgroundImage = await createImageBitmap(result.value);
            result.value.close();
        }
    }
}
