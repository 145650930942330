import { BlurBackgroundRendererInterface } from "../../interfaces/renderers/blur-background";
import { BackgroundBlurPipeline } from "../pipelines/background-blur";
import { WebglTransformerPipeline } from "../webgl-transormer-pipeline";
import { WebglRenderer } from "./webgl-renderer";

export class WebglBlurBackgroundRenderer
    extends WebglRenderer
    implements BlurBackgroundRendererInterface
{
    protected pipeline: BackgroundBlurPipeline;

    constructor(radius: number) {
        super();
        this.pipeline = new BackgroundBlurPipeline(this.context, radius);
        this.disablePostProcessing();
    }
}
