import { TextureSource } from "../../../types";
import { wait } from "../../../utils/async";
import { WEBGL_MINIMUM_TEXTURE_SIZE } from "../../../utils/webgl";
import { RendererInterface } from "../../interfaces/renderer";
import {
    FlickeringOptions,
    ImproveSegmentationMaskPipeline,
} from "../pipelines/improve-segmentation-mask";
import { ResolvedWebglQuery, WebglProfiler } from "../webgl-profiler";
import { WebglTransformerPipeline } from "../webgl-transormer-pipeline";

export abstract class WebglRenderer implements RendererInterface {
    protected canvas: OffscreenCanvas;
    protected context: WebGL2RenderingContext;
    protected pipeline?: WebglTransformerPipeline;

    private previousImageWidth: number = 0;
    private previousImageHeight: number = 0;
    private previousMaskWidth: number = WEBGL_MINIMUM_TEXTURE_SIZE;
    private previousMaskHeight: number = WEBGL_MINIMUM_TEXTURE_SIZE;
    private profiler?: WebglProfiler;

    private postProcessingEnabled: boolean = true;
    private improveMask: ImproveSegmentationMaskPipeline;

    constructor() {
        const canvas = new OffscreenCanvas(0, 0);
        const context = canvas.getContext("webgl2");

        if (!context) {
            throw "Fail to retrieve webgl2 context";
        }

        this.canvas = canvas;
        this.context = context;
        this.improveMask = new ImproveSegmentationMaskPipeline(
            this.context,
            WEBGL_MINIMUM_TEXTURE_SIZE,
            WEBGL_MINIMUM_TEXTURE_SIZE
        );
    }

    public enablePostProcessing(): void {
        this.postProcessingEnabled = true;
    }
    public disablePostProcessing(): void {
        this.postProcessingEnabled = false;
    }

    public async profileWebgl(duration: number): Promise<ResolvedWebglQuery[]> {
        const profiler = new WebglProfiler(this.context);
        this.profiler = profiler;
        profiler.start();
        await wait(duration);
        this.profiler = undefined;
        profiler.stop();
        return profiler.getResolvedQueries();
    }

    public async render(
        image: ImageBitmap,
        mask: ImageData
    ): Promise<OffscreenCanvas> {
        if (!this.pipeline) {
            throw "missing pipeline";
        }

        if (
            this.previousImageHeight !== image.height ||
            this.previousImageWidth !== image.width
        ) {
            this.resizeOutput(image.width, image.height);
        }

        if (
            this.previousMaskHeight !== mask.height ||
            this.previousMaskWidth !== mask.width
        ) {
            this.improveMask.resizeOutput(mask.width, mask.height);
            this.previousMaskWidth = mask.width;
            this.previousMaskHeight = mask.height;
        }

        const query = `renderer`;
        this.profiler?.pushContext(query);
        this.improveMask.setProfiler(this.profiler);
        this.pipeline.setProfiler(this.profiler);

        const imageBitmap = await createImageBitmap(mask);
        let finalMask: ImageBitmap | TextureSource | WebGLTexture = imageBitmap;
        if (this.postProcessingEnabled) {   
            this.improveMask.setData(image, imageBitmap);
            this.improveMask.run();
            finalMask = this.improveMask.output;
        }

        this.pipeline.setData(image, finalMask);
        await this.pipeline.run();
        this.profiler?.popContext(query);
        return this.canvas;
    }

    protected resizeOutput(width: number, height: number) {
        this.canvas.width = width;
        this.canvas.height = height;
        this.pipeline?.resizeOutput(width, height);
        this.previousImageWidth = width;
        this.previousImageHeight = height;
    }

    destroy() {
        const ext = this.context?.getExtension('WEBGL_lose_context')
        if (ext) {
            ext.loseContext();
        }
    }
}
