import prependProxyToUrlIfNeeded from './proxyUrlHelper';
import defaultEnv from './env';

/*
 * Return correct opentok static CDN url given current env and proxy
 * @param {object} deps - dependency object
 *   @property {object} env - Env object containing isProd and isRel boolean fields
 *   @property {string | undefined} proxyUrl - proxyUrl
 * @returns host - CDN host url
 */
const getCdnUrl = (deps) => {
  const {
    env = defaultEnv,
    proxyUrl,
  } = deps;
  let host;
  if (env.isProd) {
    host = 'https://static.opentok.com';
  } else if (env.isRel) {
    host = 'https://static.rel.tokbox.com';
  } else {
    host = 'https://www.dev.tokbox.com';
  }

  if (proxyUrl) {
    if (proxyUrl.includes('euproxy.opentok.com')) {
      host = 'https://static-eu.opentok.com';
    } else {
      host = prependProxyToUrlIfNeeded(host, proxyUrl);
    }
  }

  return host;
};

export default getCdnUrl;
