import { RenderingType } from '@vonage/ml-transformers';
import envDefault from '../../../helpers/env';
import getCdnUrl from '../../../helpers/getCdnUrl';

// Default configuration for @vonage/ml-transformers library
const getDefaultConfig = (deps = {}) => {
  const {
    proxyUrl,
    env = envDefault,
    ...libraryOverrideOptions
  } = deps;

  const cdnHost = getCdnUrl({ env, proxyUrl });

  return {
    modelAssetUriPath: `${cdnHost}/ml-transformers/v6.0.0/float16/vonage_selfie_segmenter.tflite`,
    mediapipeBaseAssetsUri: `${cdnHost}/ml-transformers/v6.0.0/mediapipe/0.10.20`,
    renderingOptions: { type: RenderingType.CANVAS },
    ...libraryOverrideOptions,
  };
};

export default getDefaultConfig;
