import EventEmitter from 'events';

const OBSERVER_INTERVAL = 2 * 1000;

// PressureObserver is currently on supported on recent Chromium browsers
// (Chrome 125+, Edge 125+, and Opera 111+).
// See https://developer.mozilla.org/en-US/docs/Web/API/Compute_Pressure_API &
// https://developer.chrome.com/docs/web-platform/compute-pressure
class CpuPressureMonitor extends EventEmitter {
  initMonitoring(PressureObserver) {
    const pressureChangeCallback = (pressureRecordArray) => {
      const currentState = pressureRecordArray[pressureRecordArray.length - 1].state;
      this.emit('pressureMonitorEvent', currentState);
    };

    if (PressureObserver) {
      this._observer = new PressureObserver(pressureChangeCallback);
    }
  }

  async startMonitoring() {
    if (this._isMonitoring) {
      return;
    }
    try {
      await this._observer.observe('cpu', { sampleInterval: OBSERVER_INTERVAL });
    } catch {
      // global PressureObserver feature detection can pass but still throw NotAllowedError and NotSupportedError
    }
    this._isMonitoring = true;
  }
  stopMonitoring() {
    this._isMonitoring = false;
    this._observer.unobserve('cpu');
  }
}
export default CpuPressureMonitor;
