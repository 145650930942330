import { registerWorker } from "@vonage/js-workerizer";
import {
    ErrorData,
    EventDataMap,
    MediaProcessor,
    PipelineInfoData,
    setVonageMetadata,
    VonageMetadata,
    WarnData,
} from "@vonage/media-processor";
import { BackgroundOptions, MediaProcessorConfig } from "../../main";
import { BackgroundTransformer } from "../transformers/background-transformer";
import { FlickeringOptions } from "../renderers/webgl/pipelines/improve-segmentation-mask";
import {
    ResolvedWebglQuery,
    WebglProfiler,
} from "../renderers/webgl/webgl-profiler";

export interface MediaProcessorEvent {
    name: keyof EventDataMap;
    data?: WarnData | ErrorData | PipelineInfoData;
}

export class ProcessorWorker {
    private backgroundTransformer: BackgroundTransformer =
        new BackgroundTransformer();
    private mediaProcessor: MediaProcessor = new MediaProcessor();
    private rate: number = 30;

    private resolveOnMediaProcessor?: Function;
    private eventsQueue: MediaProcessorEvent[] = [];

    private frameTransformLatencyMs: DOMHighResTimeStamp[] = [];

    public async init(id: string, config: MediaProcessorConfig) {
        this.frameTransformLatencyMs = [] as DOMHighResTimeStamp[];
        await this.backgroundTransformer.init(id, config);
        await this.mediaProcessor.setTransformers([this.backgroundTransformer]);
        this.mediaProcessor.setTrackExpectedRate(this.rate);

        this.mediaProcessor.onAny((name, data) => {
            if (this.resolveOnMediaProcessor) {
                this.resolveOnMediaProcessor({ name, data });
                this.resolveOnMediaProcessor = undefined;
            } else {
                this.eventsQueue.push({ name, data });
            }
        });
    }

    public enable() {
        this.backgroundTransformer.enable();
    }

    public disable() {
        this.backgroundTransformer.disable();
    }

    public async onMediaProcessorEvent(): Promise<MediaProcessorEvent> {
        return await new Promise((resolve) => {
            if (this.eventsQueue.length > 0) {
                resolve(this.eventsQueue.shift() as MediaProcessorEvent);
            } else {
                this.resolveOnMediaProcessor = resolve;
            }
        });
    }

    public async setTrackExpectedRate(rate: number): Promise<void> {
        this.rate = rate;
        this.mediaProcessor.setTrackExpectedRate(rate);
    }

    public async transform(
        readable: ReadableStream<any>,
        writable: WritableStream<any>
    ): Promise<void> {
        let start = performance.now();
        this.mediaProcessor.transform(readable, writable);
        this.frameTransformLatencyMs.push(performance.now() - start);
    }

    public async setBackgroundOptions(
        options: BackgroundOptions
    ): Promise<void> {
        await this.backgroundTransformer?.setBackgroundOptions(options);
    }

    public setVideoBGReadable(stream: ReadableStream) {
        this.backgroundTransformer.setVideoBGReadable(stream);
    }

    public async setVirtualBGImage(image: ImageBitmap) {
        await this.backgroundTransformer.setVirtualBGImage(image);
    }

    public async terminate(): Promise<void> {
        await this.mediaProcessor.destroy();
        const sum = this.frameTransformLatencyMs.reduce((a, b) => a + b, 0);
        const avg = (sum / this.frameTransformLatencyMs.length) || 0;
        // TODO: Think about whether to keep this here, remove it at some point
        //       or even report latency somehow (e.g. telemetry).
        console.log(`Transform latency average is: ${avg} ms`);
    }

    public setVonageMetadata(metadata: VonageMetadata) {
        setVonageMetadata(metadata);
    }

    public async profile(duration: number): Promise<string> {
        return JSON.stringify(
            await this.backgroundTransformer.profile(duration)
        );
    }

    // @TODO switch for decorator soon as they are not experimental anymore
    static {
        registerWorker("ProcessorWorker", this);
    }
}
