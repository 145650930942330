import envDefault from '../../../helpers/env';
import getCdnUrl from '../../../helpers/getCdnUrl';

const getDefaultConfig = (deps = {}) => {
  const {
    proxyUrl,
    env = envDefault,
  } = deps;

  const cdnHost = getCdnUrl({ env, proxyUrl });

  return {
    assetsDirBaseUrl: `${cdnHost}/noise-suppression/v1.0.0`,
  };
};

export default getDefaultConfig;
