import { simd } from "wasm-feature-detect";
import { isSupported as isMediaProcessorSupported } from "@vonage/media-processor";
import { FeatureSupport, SupportedBrowserFeatures } from "../types";

/**
 * Check if the current browser is officially supported by the library.
 *
 * @returns The promise will resolve or reject depending whether the browser is supported or not.
 */
export async function isSupported(): Promise<void> {
    const { wasm, insertableStreams, simd, webgl } =
        await getSupportedBrowserFeatures();

    if (!wasm.supported) {
        throw wasm.message;
    }
    if (!insertableStreams.supported) {
        throw insertableStreams.message;
    }
    if (!simd.supported) {
        throw simd.message;
    }
    if (!webgl.supported) {
        throw webgl.message;
    }
}

/**
 * Detect which features is available in the browser.
 * @returns Supported features by the browser
 */
export async function getSupportedBrowserFeatures(): Promise<SupportedBrowserFeatures> {
    const result: SupportedBrowserFeatures = {
        wasm: {
            supported:
                typeof WebAssembly === "object" &&
                typeof WebAssembly.instantiate === "function",
            message: "Your browser does not support WebAssembly features.",
        },
        simd: {
            supported: await simd(),
            message: "Your browser does not support simd features.",
        },
        insertableStreams: { supported: true },
        gpu: {
            message: "Your browser does not support gpu features.",
            supported: true, // This is a legacy check from the previous segmentation engine. now we just return true always
        },
        webgl: getWebglSupport(),
        performance: getPerformanceSupport(),
    };

    try {
        await isMediaProcessorSupported();
    } catch (e) {
        result.insertableStreams.supported = false;
        result.insertableStreams.message = e as string;
    }

    return result;
}

function getWebglSupport(): FeatureSupport {
    if (!OffscreenCanvas) {
        return {
            supported: false,
            message: "Your browser does not support offscreen canvas",
        };
    }
    const offscreenCanvas = new OffscreenCanvas(0, 0);
    const context = offscreenCanvas.getContext("webgl2");
    if (!context) {
        return {
            supported: false,
            message: "Your browser does not support webgl 2",
        };
    } else {
        const ext = context?.getExtension('WEBGL_lose_context')
        if (ext) {
            ext.loseContext();
        }
    }
    return { supported: true };
}

export function getPerformanceSupport(): FeatureSupport {
    const offscreenCanvas = new OffscreenCanvas(0, 0);
    const context = offscreenCanvas.getContext("webgl2", {
        failIfMajorPerformanceCaveat: true,
    });
    if (!context) {
        return {
            supported: false,
            message:
                "Your hardware does not perform good enough to run the library efficiently",
        };
    } else {
        const ext = context?.getExtension('WEBGL_lose_context')
        if (ext) {
            ext.loseContext();
        }
    }

    return { supported: true };
}
