import { TextureSource } from "../../../types";
import { texture } from "../../../utils/webgl";
import { RenderTexture } from "../programs/render-texture/render-texture";
import { WebglPipeline } from "../webgl-pipeline";

export class ResizeImagePipeline extends WebglPipeline {
    public inputImage?: ImageBitmap | TextureSource | WebGLTexture;

    constructor(
        private readonly context: WebGLRenderingContext,
        width: number,
        height: number
    ) {
        super();

        const defaultOptions = {
            context,
            height,
            width,
        };

        this.addStep({
            program: new RenderTexture({
                ...defaultOptions,
                disableFramebuffer: true,
            }),
            getUniforms: () => {
                return {
                    texture: texture(context, this.inputImage),
                    invert_y: true,
                };
            },
        });
    }

    public setData(image?: ImageBitmap | TextureSource | WebGLTexture) {
        this.inputImage = image;
    }
}
