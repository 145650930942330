import { BlurBackgroundRendererInterface } from "../../interfaces/renderers/blur-background";
import { BlurSilhouetteRendererInterface } from "../../interfaces/renderers/blur-silhouette";
import { WebglBlurBackgroundRenderer } from "./blur-background";
import { WebglBlurSilhouetteRenderer } from "./blur-silhouette";
import { WebglImageBackgroundRenderer } from "./image-background";
import { WebglVideoBackgroundRenderer } from "./video-background";

export class WebglRendererFactory {
    public blurBackground(radius: number): BlurBackgroundRendererInterface {
        return new WebglBlurBackgroundRenderer(radius);
    }
    public blurSilhouette(radius: number): BlurSilhouetteRendererInterface {
        return new WebglBlurSilhouetteRenderer(radius);
    }
    public imageBackground(): WebglImageBackgroundRenderer {
        return new WebglImageBackgroundRenderer();
    }
    public videoBackground(): WebglVideoBackgroundRenderer {
        return new WebglVideoBackgroundRenderer();
    }
}
