// OW-982 Only import types here, not js objects as it will load TaskRunner to the window object when file is read
import type * as TaskVision from '@mediapipe/tasks-vision';
import { WasmFileset } from './types';

export interface SelfieSegmentationInterface {
    init: (modelAssetUriPath?: string, mediapipeBaseAssetsUri?: string) => void;
    process: (input: ImageBitmap) => Promise<ImageData | void>;
    isGpuSupported: () => boolean;
}

const MIN_MASK_THRESHOLD = 0.1
const MAX_MASK_THRESHOLD = 0.7;

const DEFAULT_MODEL_ASSET_PATH = 'https://d3opqjmqzxf057.cloudfront.net/ml/vonage_selfie_segmenter/float16/v1/vonage_selfie_segmenter.tflite';

export class MediapipeSelfieSegmentation implements SelfieSegmentationInterface {
    private canvas: OffscreenCanvas = new OffscreenCanvas(0, 0);
    private context: OffscreenCanvasRenderingContext2D = this.canvas.getContext(
        "2d",
        {
            willReadFrequently: true,
        }
    ) as OffscreenCanvasRenderingContext2D;
    private width: number = 0;
    private height: number = 0;
    private previousConfidenceMask?: Float32Array;
    private mask?: ImageData;
    private imageSegmenter?: TaskVision.ImageSegmenter;
    private modelAssetUriPath?: string;
    private mediapipeBaseAssetsUri?: string;
    private wasmPath?: string;
    private wasmFileset?: WasmFileset;
    private ImageSegmenter?: typeof TaskVision.ImageSegmenter;
    private async createImageSegmenter() {
        if (!this.ImageSegmenter || !this.wasmFileset) {
            console.warn("Mediapipe objects not loaded");
            return;
        }

        this.imageSegmenter = await this.ImageSegmenter.createFromOptions(this.wasmFileset, {
            baseOptions: {
                // TODO: We might need to host the new version of this model.
                modelAssetPath: this.modelAssetUriPath,
                delegate: "GPU"
            },
            runningMode: "VIDEO",
            outputCategoryMask: true,
            outputConfidenceMasks: true
        });
    }

    private async loadMediapipeAssets(modelAssetUriPath?: string, mediapipeBaseAssetsUri?: string) {
        this.modelAssetUriPath = modelAssetUriPath || DEFAULT_MODEL_ASSET_PATH;
        const jsPath = mediapipeBaseAssetsUri ? `${mediapipeBaseAssetsUri}/task-vision.js` : 'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.20/+esm'
        const wasmPath = mediapipeBaseAssetsUri ? `${mediapipeBaseAssetsUri}/wasm` : 'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.20/wasm'

        // OW-982 We use CDN instead of npm library to avoid populating window with global objects when this file is read
        const { FilesetResolver, ImageSegmenter } = await import( /* @vite-ignore */ jsPath) as typeof TaskVision;
        this.ImageSegmenter = ImageSegmenter;
        this.wasmFileset = await FilesetResolver.forVisionTasks(wasmPath);
    }

    public async init(modelAssetUriPath?: string, mediapipeBaseAssetsUri?: string) {
        await this.loadMediapipeAssets(modelAssetUriPath, mediapipeBaseAssetsUri);
        // See https://github.com/google-ai-edge/mediapipe/issues/4694
        // @ts-ignore
        globalThis.document = {}
        await this.createImageSegmenter();
    }

    public async process(input: ImageBitmap): Promise<ImageData | void> {
        if (!this.imageSegmenter) {
            console.warn("ImageSegmenter instance not available");
            return;
        }
        if ((this.width !== input.width) || (this.height !== input.height)) {
            this.canvas.width = this.width = input.width;
            this.canvas.height = this.height = input.height;
            if (this.mask) {
                // TODO: Is it needed to free anything here before rebuilding?
                this.mask = new ImageData(
                    this.width,
                    this.height
                );
            }
        }
        if (!this.mask) {
            this.mask = new ImageData(
                this.width,
                this.height
            );
        }

        this.context!.drawImage(
            input,
            0,
            0,
            input.width,
            input.height,
        );

        // MediaPipe requires an increasing timestamp but img video sources start from 0, so changing input source can source a timestamp
        // to reset. We work around this by using Date.now() instead of frame.timestamp.
        const timestamp = Date.now();
        let confidenceMask: Float32Array | undefined;
        try {
            this.imageSegmenter?.segmentForVideo(this.context!.getImageData(0, 0, input.width, input.height), timestamp, (result) =>  {
                const confidenceMasks: TaskVision.MPMask | undefined = result.confidenceMasks?.[0];
                confidenceMask = confidenceMasks?.getAsFloat32Array();
            });
        } catch (e) {
            // In some rare cases Mediapipe may throw an error about the timestamp being smaller than the previous timestamp.
            // Mediapipe cannot recover from this so we re-create the segmenter
            // Possibly related to: https://github.com/google-ai-edge/mediapipe/issues/4769
            await this.createImageSegmenter();
            return;
        }

        if (!confidenceMask) {
            return;
        }

        if (!this.previousConfidenceMask) {
            this.previousConfidenceMask = confidenceMask.slice();
            return;
        }

        const blendedConfidenceMask = confidenceMask.map((confidenceMaskElement, index) => {
	    const previousConfidenceMaskElement = this.previousConfidenceMask?.[index];
	    if (previousConfidenceMaskElement !== undefined) {
		return this.blend(previousConfidenceMaskElement, confidenceMaskElement);
	    } else {
		return confidenceMaskElement;
	    }
        });

        const maskColor = [255, 0, 0]; // Red color for the mask
        for (let i = 0; i < blendedConfidenceMask?.length; i++) {
            const pixelConfidence = 1 - blendedConfidenceMask[i]; // pp model uses 1 for background and 0 for person
            if (pixelConfidence > MIN_MASK_THRESHOLD) {
                if (pixelConfidence < MAX_MASK_THRESHOLD) {
                    this.mask.data[i * 4] = maskColor[0] * pixelConfidence // Red
                    this.mask.data[i * 4 + 3] = 255 * pixelConfidence; // Alpha
                } else {
                    this.mask.data[i * 4] = maskColor[0]; // Red
                    this.mask.data[i * 4 + 3] = 255; // Alpha
                }
                this.mask.data[i * 4 + 1] = maskColor[1]; // Green
                this.mask.data[i * 4 + 2] = maskColor[2]; // Blue
            } else {
                this.mask.data[i * 4] = this.mask.data[i * 4 + 1] = this.mask.data[i * 4 + 2] = this.mask.data[i * 4 + 3] = 0;
            }
        }

        this.previousConfidenceMask = blendedConfidenceMask.slice();

        return this.mask;
    }

    public isGpuSupported(): boolean {
        return true;
    }

    // Typescript version of https://cs.opensource.google/mediapipe/mediapipe/+/refs/tags/v0.8.11:mediapipe/calculators/image/segmentation_smoothing_calculator.cc;l=214
    private blend(previousMask: number, currentMask: number): number {
        const c1: number = 5.68842;
        const c2: number = -0.748699;
        const c3: number = -57.8051;
        const c4: number = 291.309;
        const c5: number = -624.717;
        const t: number = currentMask - 0.5;
        const x: number = t * t;

        const uncertainty = 1.0 - Math.min(1.0, x * (c1 + x * (c2 + x * (c3 + x * (c4 + x * c5)))));
        return currentMask + (previousMask - currentMask) * (uncertainty * 0.9);
    }
}
