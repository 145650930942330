import * as twgl from "twgl.js";
import { FramebufferInfo } from "twgl.js";
import { UniformDataMap } from "../../types";
import { WebglProfiler } from "./webgl-profiler";

export interface WebglPipelineProgramOptions {
    context: WebGLRenderingContext;
    width: number;
    height: number;
    disableFramebuffer?: boolean;
}

/**
 * Webgl pipeline program plugable to a webgl pipeline
 *
 * Some uniforms are automatically updated/binded to shaders:
 *  - float delta: time difference between two run, in seconds
 *  - vec2 canvas: canvas dimension, in px
 */
export abstract class WebglPipelineProgram<
    O extends WebglPipelineProgramOptions = WebglPipelineProgramOptions
> {
    public id: string = "_";

    /**
     * Webgl context used by the program
     */
    public context: WebGLRenderingContext;

    /**
     * Texture containing the result of the program
     */
    public output: WebGLTexture;

    /**
     * Framebuffer infos used for the output of the program
     */
    public fbi: FramebufferInfo;

    /**
     * Options used to instantiate the program
     */
    public options: O;

    /**
     * Program infos
     */
    private programInfo: twgl.ProgramInfo;

    /**
     * Buffers infos used by the program
     */
    private bufferInfo: twgl.BufferInfo;
    private profiler?: WebglProfiler;

    constructor(options: O) {
        const { context, width, height } = options;
        this.context = context;
        this.options = options;

        const defines = this.buildDefines();
        this.programInfo = twgl.createProgramInfo(this.context, [
            defines + this.getVertexShader(),
            defines + this.getFragmentShader(),
        ]);
        this.bufferInfo = twgl.createBufferInfoFromArrays(
            this.context,
            this.getBuffers()
        );
        this.fbi = twgl.createFramebufferInfo(
            this.context,
            [
                {
                    format: this.context.RGBA,
                    type: this.context.UNSIGNED_BYTE,
                    min: this.context.LINEAR,
                    wrap: this.context.CLAMP_TO_EDGE,
                },
            ],
            width,
            height
        );

        this.output = this.fbi.attachments[0] as WebGLTexture;
    }

    public resizeOutput(width: number, height: number) {
        this.fbi = twgl.createFramebufferInfo(
            this.context,
            [
                {
                    format: this.context.RGBA,
                    type: this.context.UNSIGNED_BYTE,
                    min: this.context.LINEAR,
                    wrap: this.context.CLAMP_TO_EDGE,
                },
            ],
            width,
            height
        );
        this.options.width = width;
        this.options.height = height;
        this.output = this.fbi.attachments[0] as WebGLTexture;
    }

    /**
     * Return the fragment shader source
     * @returns shader source
     */
    protected abstract getFragmentShader(): string;

    /**
     * Return the vertex shader source
     * @returns shader source
     */
    protected abstract getVertexShader(): string;

    /**
     * Return a list of defines prepend to the shaders
     * @returns Object where key is the define name and value the define value
     */
    protected getDefines(): { [key: string]: number } {
        return {};
    }

    /**
     * Return a list of buffers used by the program
     * The buffers are uploaded only once at the initialization
     * @returns Buffer map
     */
    protected getBuffers(): twgl.Arrays {
        return {
            position: {
                numComponents: 2,
                data: [-1, -1, -1, 1, 1, -1, 1, 1, -1, 1, 1, -1],
            },
            texture_coord: {
                numComponents: 2,
                data: [0, 0, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0],
            },
        };
    }

    /**
     * Build prependable string to shaders source containing the defines returned by getDefines.
     * @returns Prependable string
     */
    private buildDefines(): string {
        let result = "";
        const defines = this.getDefines();
        for (let i in defines) {
            result += `#define ${i} ${defines[i].toFixed(1)}\n`;
        }
        return result;
    }

    /**
     * Last time the program run.
     * Used to compute delta uniform
     */
    private lastRun?: number;

    /**
     * Run the program
     * @param uniforms Data to used for the uniforms
     */
    public run(uniforms: UniformDataMap) {
        this.profiler?.pushContext(
            `[${this.id}] PROG : ${this.constructor.name}`
        );

        this.profiler?.pushContext(`[${this.id}] UNIFORMS`);
        for (const u in uniforms) {
            if (typeof uniforms[u] === "function") {
                this.profiler?.pushContext(`[${this.id}] UNI : ${u}`);
                uniforms[u] = (uniforms[u] as any)();
                this.profiler?.popContext(`[${this.id}] UNI : ${u}`);
            }
        }
        this.profiler?.popContext(`[${this.id}] UNIFORMS`);

        const now = Date.now();
        let delta = 0;
        if (this.lastRun) {
            delta = (now - this.lastRun) / 1000;
        }
        this.lastRun = now;

        this.context.viewport(
            0,
            0,
            this.context.canvas.width,
            this.context.canvas.height
        );
        this.context.useProgram(this.programInfo.program);
        twgl.setBuffersAndAttributes(
            this.context,
            this.programInfo,
            this.bufferInfo
        );
        twgl.setUniforms(this.programInfo, {
            delta,
            canvas: [this.context.canvas.width, this.context.canvas.height],
            ...uniforms,
        });
        twgl.bindFramebufferInfo(
            this.context,
            this.options.disableFramebuffer ? null : this.fbi
        );
        twgl.drawBufferInfo(this.context, this.bufferInfo);

        this.profiler?.popContext(
            `[${this.id}] PROG : ${this.constructor.name}`
        );
    }

    public setProfiler(profiler?: WebglProfiler) {
        this.profiler = profiler;
    }
}
