import {
    BackgroundOptions,
    RenderingType,
    WebglSelfieSegmentationType,
} from "../types";
import { RendererFactory } from "../renderers/factory";
import { RendererInterface } from "../renderers/interfaces/renderer";
import { FlickeringOptions } from "../renderers/webgl/pipelines/improve-segmentation-mask";
import { ResolvedWebglQuery } from "../renderers/webgl/webgl-profiler";
import { BlurRadius } from "../types";

export interface BackgroundFilterConfig {
    maskWidth: number;
    maskHeight: number;
    foregroundWidth: number;
    foregroundHeight: number;
}

export class BackgroundFilter {
    private renderer?: RendererInterface;

    private backgroundVideo:
        | ReadableStreamDefaultReader<VideoFrame>
        | undefined;
    private backgroundImage: ImageBitmap | undefined;

    public setBackgroundOptions(options: BackgroundOptions) {
        if (this.renderer) {
            this.renderer.destroy()
        }
        const factory = new RendererFactory(options.renderingOptions?.type);
        switch (options.transformerType) {
            case "BackgroundBlur":
                this.renderer = factory.blurBackground(
                    this.getBlurSize(options.radius)
                );
                break;
            case "SilhouetteBlur":
                this.renderer = factory.blurSilhouette(
                    this.getBlurSize(options.radius)
                );
                break;
            case "VideoBackground":
                const videoRenderer = factory.videoBackground();
                if (this.backgroundVideo) {
                    videoRenderer.setBackgroundVideo(this.backgroundVideo);
                }
                this.renderer = videoRenderer;
                break;
            case "VirtualBackground":
                const imageRenderer = factory.imageBackground();
                if (this.backgroundImage) {
                    imageRenderer.setBackgroundImage(this.backgroundImage);
                }
                this.renderer = imageRenderer;
                break;
            default:
                throw `Unknown rendering type [${options.transformerType}]`;
        }
        if (options.renderingOptions?.type === RenderingType.WEBGL) {
            if (
                options.renderingOptions.selfieSegmentationType ===
                WebglSelfieSegmentationType.PRECISE
            ) {
                this.renderer.enablePostProcessing();
            } else if (
                options.renderingOptions.selfieSegmentationType ===
                WebglSelfieSegmentationType.FAST
            ) {
                this.renderer.disablePostProcessing();
            }
        }
    }

    public setVideoBGReadable(video: ReadableStream) {
        this.backgroundVideo = video.getReader();
        const rendererAsAny = this.renderer as any;
        if (rendererAsAny?.setBackgroundVideo) {
            rendererAsAny.setBackgroundVideo(this.backgroundVideo);
        }
    }

    public async setVirtualBGImage(image: ImageBitmap) {
        const rendererAsAny = this.renderer as any;
        if (rendererAsAny?.setBackgroundImage) {
            rendererAsAny.setBackgroundImage(image);
        }
        this.backgroundImage = image;
    }

    public async process(
        foreground: ImageBitmap,
        mask: ImageData
    ): Promise<OffscreenCanvas | void> {
        if (this.renderer) {
            return this.renderer.render(foreground, mask);
        }
    }

    public async resizeForeground(width: number, height: number) {}

    private getBlurSize(radius: number | BlurRadius = BlurRadius.Low): number {
        switch (radius) {
            case BlurRadius.Low:
                return 5;
            case BlurRadius.High:
                return 12;
        }
        return radius;
    }

    public async profile(duration: number): Promise<ResolvedWebglQuery[]> {
        return this.renderer?.profileWebgl(duration) ?? [];
    }
}
