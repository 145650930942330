//@ts-ignore
import VonageSelfieSegmentation from "../../libs/selfie-segmentation-helper";
//@ts-ignore
import VonageHands from "../../libs/hands-helper";
//@ts-ignore
import VonageHolistic from "../../libs/holistic-helper";
//@ts-ignore
import VonageFacedetection from "../../libs/face-detection-helper";
//@ts-ignore
import VonageFacemash from "../../libs/face-mash-helper";
//@ts-ignore
import VonageObjectron from "../../libs/objectron-helper";
//@ts-ignore
import VonagePose from "../../libs/pose-helper";

import { MediapipeConfig, MediaPipeModelType } from "../types";

export class MediapipeHelper {
    private mediapipeMap_: Map<string, any> = new Map();
    private static defaultAssetsBaseUrl: string =
        "https://d3opqjmqzxf057.cloudfront.net/ml/mediapipe/";

    constructor() {}

    private getVersion(modelType: MediaPipeModelType): string {
        switch (modelType) {
            case "face_detection":
                return VonageFacedetection.VERSION;
            case "face_mesh":
                return VonageFacemash.VERSION;
            case "hands":
                return VonageHands.VERSION;
            case "holistic":
                return VonageHolistic.VERSION;
            case "objectron":
                return VonageObjectron.VERSION;
            case "selfie_segmentation":
                return VonageSelfieSegmentation.VERSION;
            case "pose":
                return VonagePose.VERSION;
            default:
                return "";
        }
    }

    initialize(config: MediapipeConfig): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (config.mediaPipeModelConfigArray.length == 0) {
                reject("no model was selected");
                return;
            }

            for (
                let index = 0;
                index < config.mediaPipeModelConfigArray.length;
                index++
            ) {
                const mediaPipeModelConfig =
                    config.mediaPipeModelConfigArray[index];
                if (this.mediapipeMap_.has(mediaPipeModelConfig.modelType)) {
                    reject("setting same type is not supported");
                    return;
                }

                if (typeof mediaPipeModelConfig.listener != "function") {
                    reject(
                        `[${mediaPipeModelConfig.modelType}] ResultsListener must be provided`
                    );
                    return;
                }

                let tempMediaPipe: any;
                switch (mediaPipeModelConfig.modelType) {
                    case "face_mesh":
                        tempMediaPipe = new VonageFacemash.FaceMesh({
                            locateFile: (path: string, prefix?: string) => {
                                let baseUrl =
                                    mediaPipeModelConfig.assetsUri ||
                                    MediapipeHelper.defaultAssetsBaseUrl +
                                        "face_mesh/" +
                                        this.getVersion("face_mesh");
                                return baseUrl + "/" + path;
                            },
                        });
                        break;
                    case "face_detection":
                        tempMediaPipe = new VonageFacedetection.FaceDetection({
                            locateFile: (path: string, prefix?: string) => {
                                let baseUrl =
                                    mediaPipeModelConfig.assetsUri ||
                                    MediapipeHelper.defaultAssetsBaseUrl +
                                        "face_detection/" +
                                        this.getVersion("face_detection");
                                return baseUrl + "/" + path;
                            },
                        });
                        break;
                    case "hands":
                        tempMediaPipe = new VonageHands.Hands({
                            locateFile: (path: string, prefix?: string) => {
                                let baseUrl =
                                    mediaPipeModelConfig.assetsUri ||
                                    MediapipeHelper.defaultAssetsBaseUrl +
                                        "hands/" +
                                        this.getVersion("hands");
                                return baseUrl + "/" + path;
                            },
                        });
                        break;
                    case "holistic":
                        tempMediaPipe = new VonageHolistic.Holistic({
                            locateFile: (path: string, prefix?: string) => {
                                let baseUrl =
                                    mediaPipeModelConfig.assetsUri ||
                                    MediapipeHelper.defaultAssetsBaseUrl +
                                        "holistic/" +
                                        this.getVersion("holistic");
                                return baseUrl + "/" + path;
                            },
                        });
                        break;
                    case "objectron":
                        tempMediaPipe = new VonageObjectron.Objectron({
                            locateFile: (path: string, prefix?: string) => {
                                let baseUrl =
                                    mediaPipeModelConfig.assetsUri ||
                                    MediapipeHelper.defaultAssetsBaseUrl +
                                        "objectron/" +
                                        this.getVersion("objectron");
                                return baseUrl + "/" + path;
                            },
                        });
                        break;
                    case "selfie_segmentation":
                        tempMediaPipe =
                            new VonageSelfieSegmentation.SelfieSegmentation({
                                locateFile: (path: string, prefix?: string) => {
                                    let baseUrl =
                                        mediaPipeModelConfig.assetsUri ||
                                        MediapipeHelper.defaultAssetsBaseUrl +
                                            "selfie_segmentation/" +
                                            this.getVersion(
                                                "selfie_segmentation"
                                            );
                                    return baseUrl + "/" + path;
                                },
                            });
                        break;
                    case "pose":
                        tempMediaPipe = new VonagePose.Pose({
                            locateFile: (path: string, prefix?: string) => {
                                let baseUrl =
                                    mediaPipeModelConfig.assetsUri ||
                                    MediapipeHelper.defaultAssetsBaseUrl +
                                        "pose/" +
                                        this.getVersion("pose");
                                return baseUrl + "/" + path;
                            },
                        });
                        break;
                    default:
                        reject("unknown model type");
                        return;
                }
                tempMediaPipe.onResults(mediaPipeModelConfig.listener);
                if (mediaPipeModelConfig.options) {
                    tempMediaPipe.setOptions(mediaPipeModelConfig.options);
                }
                try {
                    await tempMediaPipe.initialize();
                    this.mediapipeMap_.set(
                        mediaPipeModelConfig.modelType,
                        tempMediaPipe
                    );
                } catch (e) {
                    reject(e);
                    return;
                }
            }
            resolve();
        });
    }

    close(modelType?: MediaPipeModelType): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let promises = [];
            if (modelType) {
                let tempMediaPipe = this.mediapipeMap_.get(modelType);
                if (tempMediaPipe) {
                    this.mediapipeMap_.delete(modelType);
                    promises.push(tempMediaPipe.close());
                } else {
                    reject("model not exit");
                    return;
                }
            } else {
                for (const [
                    key,
                    tempMediaPipe,
                ] of this.mediapipeMap_.entries()) {
                    promises.push(tempMediaPipe.close());
                }
                this.mediapipeMap_.clear();
            }
            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    send(
        image:
            | HTMLVideoElement
            | HTMLImageElement
            | HTMLCanvasElement
            | ImageBitmap
            | OffscreenCanvas,
        modelType?: MediaPipeModelType
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let promises = [];
            if (modelType) {
                let tempMediaPipe = this.mediapipeMap_.get(modelType);
                if (tempMediaPipe) {
                    // @ts-ignore
                    promises.push(tempMediaPipe.send({ image: image }));
                } else {
                    reject("model not exit");
                    return;
                }
            } else {
                for (const [
                    key,
                    tempMediaPipe,
                ] of this.mediapipeMap_.entries()) {
                    // @ts-ignore
                    promises.push(tempMediaPipe.send({ image: image }));
                }
            }
            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    reset(modelType?: MediaPipeModelType): void {
        if (modelType) {
            let tempMediaPipe = this.mediapipeMap_.get(modelType);
            if (tempMediaPipe && typeof tempMediaPipe.reset === "function") {
                tempMediaPipe.reset();
            }
        } else {
            for (const [key, tempMediaPipe] of this.mediapipeMap_.entries()) {
                if (
                    tempMediaPipe &&
                    typeof tempMediaPipe.reset === "function"
                ) {
                    tempMediaPipe.reset();
                }
            }
        }
    }
}
