// @ts-ignore
import {
    ErrorData,
    EventDataMap,
    MediaProcessorConnector,
    PipelineInfoData,
    WarnData,
} from "@vonage/media-processor";
import Emittery from "emittery";
import { BackgroundOptions, MediaProcessorConfig } from "../../main";
import { ProcessorMain } from "../processors/processor-main";
import { ResolvedWebglQuery } from "../renderers/webgl/webgl-profiler";
import { WebglProfilerReporter } from "../renderers/webgl/webgl-profiler-reporter";

/**
 * Class wrapping features provided by ml-transformers.
 */
export class VonageMediaProcessor extends Emittery<EventDataMap> {
    /**
     * Web worker wrapper used to run the transformers
     * @internal
     */
    private worker;

    /**
     * Usable connector for media-processor lib
     * @internal
     */
    private connector;

    /**
     * Private constructor
     * Use {@link create} to instantiate the class
     * @internal
     */
    private constructor(config: MediaProcessorConfig) {
        super();

        this.worker = new ProcessorMain(config);
        this.connector = new MediaProcessorConnector(this.worker);

        this.worker.onAny(
            (
                name: keyof EventDataMap,
                data: WarnData | ErrorData | PipelineInfoData
            ) => this.emit(name, data)
        );
    }

    /**
     * change the background option during run time using this function.
     * while using this function the media-processor will not be destroyed.
     * while using this function the library promise a full resource cleanup.
     * @param backgroundOptions - see `BackgroundOptions` definition
     */
    public async setBackgroundOptions(options: BackgroundOptions) {
        await this.worker.setBackgroundOptions(options);
    }

    /**
     * Enable the processing
     */
    public async enable() {
        await this.worker.enable();
    }

    /**
     * Disable the processing
     */
    public async disable() {
        await this.worker.disable();
    }

    /**
     * Sets the expected rate of the track per second.
     * The media processor will use this number for calculating drops in the rate.
     * This could happen when the transformation will take more time than expected.
     * This will not cause an error, just warning to the client.
     * Mostly:
     * Video: 30 frames per second
     * Audio: 50 audio data per second for OPUS
     * @param rate - number holds the predicted track rate. -1 for disable this monitor.
     */
    public setTrackExpectedRate(rate: number) {
        this.worker.setTrackExpectedRate(rate);
    }

    /**
     * Getter for MediaProcessorConnectorInterface connector attribute.
     * @returns - `MediaProcessorConnectorInterface` feed this return value to any vonage SDK that supports this API
     */
    public getConnector(): MediaProcessorConnector {
        return this.connector;
    }

    public async profile(duration: number): Promise<ResolvedWebglQuery[]> {
        return this.worker.profile(duration);
    }

    /**
     * @internal
     */
    public static async profile(
        duration: number
    ): Promise<WebglProfilerReporter> {
        const profiles = await Promise.all(
            this.instances.map((instance) => instance.profile(duration))
        );
        return new WebglProfilerReporter(profiles);
    }

    private static instances: VonageMediaProcessor[] = [];
    /**
     * Asynchronous constructor of VonageMediaProcessor
     * @param config Initial MediaProcessorConfig to use
     * @returns Promise resolved with an initialized MediaProcessorConfig
     */
    public static async create(
        config: MediaProcessorConfig
    ): Promise<VonageMediaProcessor> {
        const result = new this(config);
        this.instances.push(result);
        return result;
    }
}
