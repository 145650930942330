import staticConfigFactory from './StaticConfig';

const staticConfig = staticConfigFactory().onlyLocal();

const isSupportedBrowser = (browser) => {
  const supportedBrowsers = Object.keys(staticConfig.minimumVersion);
  return supportedBrowsers.includes(browser);
};

const isSupportedBrowserVersion = (browser, version) => {
  const minimumSupportedBrowserVersion = staticConfig.minimumVersion[browser];
  return minimumSupportedBrowserVersion <= version;
};

export default (browserName, browserVersion) => {
  const browser = browserName.toLowerCase();
  const supportedBrowser = isSupportedBrowser(browser);
  const supportedBrowserVersion = isSupportedBrowserVersion(browser, browserVersion);

  return {
    isBrowserAndVersionSupported: () => supportedBrowser && supportedBrowserVersion,
    isSupportedButOld: () => supportedBrowser && !supportedBrowserVersion,
  };
};
