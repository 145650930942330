import eventNames from '../../helpers/eventNames';
import now from '../../helpers/now';
import RafRunner from '../RafRunner';
import EventsFactory from '../events';

const Events = EventsFactory();

export default ({ subscriber, audioLevelSampler }) => {
  let loudness = 0;
  let loudnessLastAccess = 0;
  let destroyed = false;

  const audioLevelRunner = new RafRunner(() => {
    const listenerCount = subscriber.listenerCount(eventNames.AUDIO_LEVEL_UPDATED);
    const shouldStopWatchingAudio = () => now() - loudnessLastAccess > 10000 && listenerCount === 0;

    if (shouldStopWatchingAudio()) {
      audioLevelRunner.stop();
      return;
    }

    loudness = audioLevelSampler.sample();

    if (listenerCount > 0) {
      // dispatch this old event if someone is listening...
      subscriber.dispatchEvent(new Events.AudioLevelUpdatedEvent(loudness));
    }
  });

  const audioLevelUpdatedAdded = () => audioLevelRunner.start();
  const subscriberDestroyed = () => audioLevelRunner.stop();

  const addListeners = () => {
    subscriber.on('audioLevelUpdated:added', audioLevelUpdatedAdded);
    subscriber.once(eventNames.SUBSCRIBER_DESTROYED, subscriberDestroyed);
  };
  const removeListeners = () => {
    subscriber.off('audioLevelUpdated:added', audioLevelUpdatedAdded);
    subscriber.off(eventNames.SUBSCRIBER_DESTROYED, subscriberDestroyed);
  };

  Object.defineProperty(subscriber, 'loudness', {
    get() {
      if (!destroyed) {
        audioLevelRunner.start();
      }
      loudnessLastAccess = now();
      return loudness;
    },
    set() {
      throw new TypeError('Cannot set readonly property loudness');
    },
    configurable: true,
  });

  if (subscriber.listenerCount(eventNames.AUDIO_LEVEL_UPDATED)) {
    audioLevelRunner.start();
  }

  addListeners();

  return {
    destroy() {
      destroyed = true;
      audioLevelSampler.destroy();
      audioLevelRunner.stop();
      removeListeners();
      // eslint-disable-next-line no-param-reassign
      subscriber = undefined;
    },
  };
};
