import { VideoBackgroundRendererInterface } from "../../interfaces/renderers/video-background";
import { WebglImageBackgroundRenderer } from "../../webgl/renderers/image-background";
import { Canvas2dRenderer } from "./canvas2d-renderer";

export class Canvas2dVideoBackgroundRenderer
    extends WebglImageBackgroundRenderer
    implements VideoBackgroundRendererInterface
{
    private frameReader?: ReadableStreamDefaultReader<VideoFrame>;

    public setBackgroundVideo(reader: ReadableStreamDefaultReader<VideoFrame>) {
        this.frameReader = reader;
    }

    public async render(
        image: ImageBitmap,
        mask: ImageData
    ): Promise<OffscreenCanvas> {
        await this.readNextVideoFrame();
        return super.render(image, mask);
    }

    private async readNextVideoFrame() {
        const result = await this.frameReader?.read();
        if (result?.value) {
            const bitmap = await createImageBitmap(result.value);
            this.setBackgroundImage(bitmap);
            result.value.close();
        }
    }
}
