import { Report, Reporter } from "@vonage/js-onewebrtc-telemetry";
import { version } from "../../../package.json";
import { BackgroundOptions, MediaProcessorConfig } from "../../main";
import { BackgroundFilter } from "../filters/background-filter";
import { SelfieSegmentationInterface, MediapipeSelfieSegmentation } from "../wasm/selfie-segmentation/wrapper";
import { ResolvedWebglQuery } from "../renderers/webgl/webgl-profiler";

export class BackgroundTransformer {
    private selfieSegmentation?: SelfieSegmentationInterface;
    private backgroundFilter?: BackgroundFilter;
    private reporter = new Reporter();
    private isEnabled = true;

    public async init(id: string, config: MediaProcessorConfig) {
        this.selfieSegmentation = new MediapipeSelfieSegmentation();
        await this.selfieSegmentation?.init(config.modelAssetUriPath, config.mediapipeBaseAssetsUri);

        this.backgroundFilter = new BackgroundFilter();
        this.report({
            variation: "Create",
        });
    }

    public async transform?(
        frame: VideoFrame,
        controller: TransformStreamDefaultController
    ) {
        if (!this.isEnabled) {
            return controller.enqueue(frame);
        }

        let canvas;
        const timestamp = frame.timestamp ?? 0;
        const input = await createImageBitmap(frame);
        const mask = await this.selfieSegmentation?.process(input);
        if (mask) {
            canvas = await this.backgroundFilter?.process(input, mask);
        }

        if (canvas) {
            frame.close();
            controller.enqueue(
                new VideoFrame(canvas as any as HTMLCanvasElement, {
                    timestamp,
                    alpha: "discard",
                })
            );
        } else {
            controller.enqueue(frame);
        }
    }

    private backgroundOptions?: BackgroundOptions;
    public async setBackgroundOptions(options: BackgroundOptions) {
        this.backgroundOptions = options;
        await this.backgroundFilter?.setBackgroundOptions(options);
        this.report({
            variation: "Update",
        });
    }

    public async setVideoBGReadable(stream: ReadableStream) {
        await this.backgroundFilter?.setVideoBGReadable(stream);
    }

    public async setVirtualBGImage(image: ImageBitmap) {
        await this.backgroundFilter?.setVirtualBGImage(image);
    }

    public async profile(duration: number): Promise<ResolvedWebglQuery[]> {
        return this.backgroundFilter?.profile(duration) ?? [];
    }

    public getTransformerType(): string {
        return this.backgroundOptions?.transformerType ?? "Undefined";
    }

    public enable() {
        this.isEnabled = true;
    }

    public disable() {
        this.isEnabled = false;
    }

    private report(report: Partial<Report>) {
        this.reporter.send({
            version,
            action: "MlTransformers",
            transformerType: this.backgroundOptions?.transformerType,
            config: JSON.stringify(this.backgroundOptions),
            ...report,
        });
    }
}
