/* global HTMLMediaElement */
/* eslint-disable no-console */

import envDefault from './env';

// This is a subset of all possible HTMLMediaElement events. We leave some because we
// either don't care about it (`encrypted`) or they might be too noisy (e.g., `timeupdate`).
const mediaElementEvents = [
  'abort', 'canplay', 'canplaythrough', 'durationchange', 'emptied', 'ended', 'error', 'loadeddata',
  'loadedmetadata', 'loadstart', 'pause', 'play', 'playing', 'progress', 'ratechange', 'seeked',
  'seeking', 'stalled', 'suspend', 'volumechange', 'waiting',
];

const eventHandlers = {};

const isValidElementAndEnv = (mediaElement, env) => {
  if (!env?.isDev) {
    console.debug("Not in DEV, so won't add/remove debug listeners");
    return false;
  }
  if (!(mediaElement instanceof HTMLMediaElement)) {
    console.error('mediaElementListeners: Invalid HTMLMediaElement');
    return false;
  }
  return true;
};

const addMediaElementListeners = (mediaElement, triggerEvent, deps = {}) => {
  const env = deps.env || envDefault;
  if (!isValidElementAndEnv(mediaElement, env)) return;

  mediaElementEvents.forEach((eventName) => {
    eventHandlers[eventName] = (event) => {
      triggerEvent(`mediaElement:${eventName}`);
      console.debug(`HTMLMediaElement triggered: ${eventName}`, event);
    };
    mediaElement.addEventListener(eventName, eventHandlers[eventName]);
  });
};

const removeMediaElementListeners = (mediaElement, deps = {}) => {
  const env = deps.env || envDefault;
  if (!isValidElementAndEnv(mediaElement, env)) return;

  mediaElementEvents.forEach((event) => {
    mediaElement.removeEventListener(event, eventHandlers[event]);
    delete eventHandlers[event];
  });
};

export { addMediaElementListeners, removeMediaElementListeners };
