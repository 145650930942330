import { count } from "console";
import { wait } from "../../utils/async";

/**
 * @internal
 */
export enum WebglQueryAction {
    PUSH = "PUSH",
    POP = "POP",
}

/**
 * @internal
 */
export interface WebglQuery {
    name: string;
    action: WebglQueryAction;
    query: WebGLQuery;
}

/**
 * @internal
 */
export interface ResolvedWebglQuery extends WebglQuery {
    duration: number;
    timestamp: number;
}

/**
 * @internal
 */
export class WebglProfiler {
    private context: WebGL2RenderingContext;
    private extension: any;
    private queries: WebglQuery[] = [];
    private activeQuery?: WebGLQuery;

    constructor(context: WebGL2RenderingContext) {
        this.context = context;
        this.extension = context.getExtension(
            "EXT_disjoint_timer_query_webgl2"
        );
    }

    public start(): void {}

    public stop(): void {
        if (this.activeQuery) {
            this.context.endQuery(this.extension.TIME_ELAPSED_EXT);
        }
    }

    public pushContext(name: string): void {
        this.createQuery(WebglQueryAction.PUSH, name);
    }

    public popContext(name: string): void {
        this.createQuery(WebglQueryAction.POP, name);
    }

    private createQuery(action: WebglQueryAction, name: string): void {
        if (this.activeQuery) {
            this.context.endQuery(this.extension.TIME_ELAPSED_EXT);
        }
        this.activeQuery = this.context.createQuery() as WebGLQuery;
        this.context.beginQuery(
            this.extension.TIME_ELAPSED_EXT,
            this.activeQuery
        );
        this.queries.push({
            name,
            action,
            query: this.activeQuery,
        });
    }

    public async getResolvedQueries(): Promise<ResolvedWebglQuery[]> {
        const queries = (
            await Promise.all(
                [...this.queries].map((query) => this.resolveQuery(query))
            )
        ).filter((q) => q) as ResolvedWebglQuery[];

        for (let i = 1; i < queries.length; ++i) {
            queries[i].timestamp +=
                queries[i - 1].duration + queries[i - 1].timestamp;
        }

        return queries;
    }

    private async resolveQuery(
        query: WebglQuery
    ): Promise<ResolvedWebglQuery | undefined> {
        if (!(await this.awaitQueryAvailable(query))) {
            console.log("Unawaitable query", query);
            return;
        }
        let disjoint = this.context.getParameter(
            this.extension.GPU_DISJOINT_EXT
        );

        if (disjoint) {
            console.log("Disjointed query", query);
            return;
        }

        return {
            ...query,
            duration: this.context.getQueryParameter(
                query.query,
                this.context.QUERY_RESULT
            ),
            timestamp: 0,
        };
    }

    private async awaitQueryAvailable(
        query: WebglQuery
    ): Promise<WebglQuery | undefined> {
        for (let i = 0; i < 10; ++i) {
            let available = this.context.getQueryParameter(
                query.query,
                this.context.QUERY_RESULT_AVAILABLE
            );
            if (available) {
                return query;
            }
            await wait(200);
        }
        return undefined;
    }
}
