import { off } from "process";
import { RenderingType } from "../types";
import {
    getPerformanceSupport,
    getSupportedBrowserFeatures,
} from "../utils/support";
import { Canvas2dRendererFactory } from "./canvas2d/renderers/factory";
import { RendererFactoryInterface } from "./interfaces/factory";
import { BlurBackgroundRendererInterface } from "./interfaces/renderers/blur-background";
import { BlurSilhouetteRendererInterface } from "./interfaces/renderers/blur-silhouette";
import { ImageBackgroundRendererInterface } from "./interfaces/renderers/image-background";
import { VideoBackgroundRendererInterface } from "./interfaces/renderers/video-background";
import { WebglRendererFactory } from "./webgl/renderers/factory";

export class RendererFactory implements RendererFactoryInterface {
    private factory?: RendererFactoryInterface;

    constructor(renderingType?: RenderingType) {
        switch (renderingType) {
            case RenderingType.CANVAS:
                this.factory = new Canvas2dRendererFactory();
                break;
            case RenderingType.WEBGL:
                this.factory = new WebglRendererFactory();
                break;
            default:
                const feature = getPerformanceSupport();
                this.factory = feature.supported
                    ? new WebglRendererFactory()
                    : new Canvas2dRendererFactory();
        }
    }

    public blurBackground(radius: number): BlurBackgroundRendererInterface {
        return this.getFactory().blurBackground(radius);
    }
    public blurSilhouette(radius: number): BlurSilhouetteRendererInterface {
        return this.getFactory().blurSilhouette(radius);
    }
    public imageBackground(): ImageBackgroundRendererInterface {
        return this.getFactory().imageBackground();
    }
    public videoBackground(): VideoBackgroundRendererInterface {
        return this.getFactory().videoBackground();
    }

    private getFactory(): RendererFactoryInterface {
        if (!this.factory) {
            throw "Factory is not initialized";
        }
        return this.factory;
    }
}
