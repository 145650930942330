import {
    WebglPipelineProgram,
    WebglPipelineProgramOptions,
} from "../../webgl-pipeline-program";
import fragmentShader from "./blur-masked.frag?raw";
import vertexShader from "./blur-masked.vert?raw";

export interface BlurMaskedOptions extends WebglPipelineProgramOptions {
    radius?: number;
}

export class BlurMasked extends WebglPipelineProgram<BlurMaskedOptions> {
    protected getDefines(): { [key: string]: number } {
        return { RADIUS: this.options.radius ?? 1 };
    }
    protected getFragmentShader(): string {
        return fragmentShader + this.createLoopFunction();
    }

    protected getVertexShader(): string {
        return vertexShader;
    }

    private createLoopFunction(): string {
        let radius = this.options.radius ?? 1;
        return `

        vec4 loop() { 
            vec4 result = vec4(0,0,0,0);
            float factorSum = 0.;

            vec2 normalizedRadius = vec2(-${radius}.) / canvas;
            vec2 normalizedIncrement = vec2(1.) / canvas;
            vec2 diff = normalizedRadius;
            float maxLength = length(normalizedRadius);

            for(int y=-${radius}; y<${radius + 1}; ++y) {
                for(int x=-${radius}; x<${radius + 1}; ++x) {
                    float factor = (1.- texture2D(mask, _texture_coord + diff).a) * (maxLength - length(diff)) / maxLength;
                    result += factor * texture2D(texture, _texture_coord + diff);
                    factorSum += factor;
                    diff.x += normalizedIncrement.x;
                }
                diff.y += normalizedIncrement.y;
                diff.x = normalizedRadius.x;
            }
            return result / factorSum;
        }`;
    }
}
