import { TextureSource } from "../../../types";
import { texture } from "../../../utils/webgl";
import { Blur, BLUR_TYPE } from "../programs/blur/blur";
import { Mix } from "../programs/mix/mix";
import { WebglTransformerPipeline } from "../webgl-transormer-pipeline";

export class ForegroundBlurPipeline extends WebglTransformerPipeline {
    public inputImage?: ImageBitmap | TextureSource | WebGLTexture;
    public inputMask?: ImageBitmap | TextureSource | WebGLTexture;

    constructor(
        private readonly context: WebGLRenderingContext,
        radius: number
    ) {
        super();

        const defaultOptions = {
            context,
            height: context.canvas.height,
            width: context.canvas.width,
        };

        const blur = new Blur({
            ...defaultOptions,
            radius,
            type: BLUR_TYPE.GAUSSIAN,
        });
        const mix = new Mix({ ...defaultOptions, disableFramebuffer: true });

        this.addStep({
            program: blur,
            getUniforms: () => {
                return {
                    texture: texture(context, this.inputImage),
                };
            },
        });

        this.addStep({
            program: mix,
            getUniforms: () => {
                return {
                    background: texture(context, this.inputImage),
                    foreground: texture(context, blur.output),
                    threshold: texture(context, this.inputMask),
                };
            },
        });
    }

    public setData(
        image?: ImageBitmap | TextureSource | WebGLTexture,
        mask?: ImageBitmap | TextureSource | WebGLTexture
    ) {
        this.inputImage = image;
        this.inputMask = mask;
    }
}
