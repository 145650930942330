import { TextureSource } from "../../types";
import { WebglPipeline } from "./webgl-pipeline";

export abstract class WebglTransformerPipeline extends WebglPipeline {
    public preciseMaskEnabled: boolean = false;

    public abstract setData(
        image?: ImageBitmap | TextureSource | WebGLTexture,
        mask?: ImageBitmap | TextureSource | WebGLTexture
    ): void;
}
