import { TextureSource } from "../../../types";
import { texture, WEBGL_MINIMUM_TEXTURE_SIZE } from "../../../utils/webgl";
import { BlurMasked } from "../programs/blur-masked/blur-masked";
import { Mix } from "../programs/mix/mix";
import { RenderTexture } from "../programs/render-texture/render-texture";
import { WebglTransformerPipeline } from "../webgl-transormer-pipeline";

const THRESHOLD_RADIUS_FACTOR = 10;
const LOW_RADIUS_FACTOR = 2;
const HIGH_RADIUS_FACTOR = 8;

export class BackgroundBlurPipeline extends WebglTransformerPipeline {
    public inputImage?: ImageBitmap | TextureSource | WebGLTexture;
    public inputMask?: ImageBitmap | TextureSource | WebGLTexture;

    private blur: BlurMasked;

    constructor(
        private readonly context: WebGLRenderingContext,
        private radius: number
    ) {
        super();

        const defaultOptions = {
            context,
            height: context.canvas.height,
            width: context.canvas.width,
        };
        this.blur = new BlurMasked({
            ...defaultOptions,
            radius,
            ...this.getBlurTextureSize(
                context.canvas.width,
                context.canvas.height
            ),
        });
        const mix = new Mix({ ...defaultOptions, disableFramebuffer: true });
        this.addStep({
            program: this.blur,
            preventResize: true,
            getUniforms: () => {
                return {
                    texture: texture(context, this.inputImage),
                    mask: texture(context, this.inputMask),
                };
            },
        });

        this.addStep({
            program: mix,
            getUniforms: () => {
                return {
                    background: texture(context, this.blur.output),
                    foreground: texture(context, this.inputImage),
                    threshold: texture(context, this.inputMask),
                };
            },
        });
    }
    private getBlurTextureSize(
        width: number,
        height: number
    ): { width: number; height: number } {
        const factor =
            this.radius > THRESHOLD_RADIUS_FACTOR
                ? HIGH_RADIUS_FACTOR
                : LOW_RADIUS_FACTOR;
        return {
            width: Math.max(WEBGL_MINIMUM_TEXTURE_SIZE, width / factor),
            height: Math.max(WEBGL_MINIMUM_TEXTURE_SIZE, height / factor),
        };
    }

    public setData(
        image?: ImageBitmap | TextureSource | WebGLTexture,
        mask?: ImageBitmap | TextureSource | WebGLTexture
    ) {
        this.inputImage = image;
        this.inputMask = mask;
    }

    public resizeOutput(width: number, height: number) {
        super.resizeOutput(width, height);
        const blurSize = this.getBlurTextureSize(width, height);
        this.blur.resizeOutput(blurSize.width, blurSize.height);
    }
}
