import env from './env';

/**
 * We need frame rate watcher in Safari because framesPerSecond is
 * always zero so we need to rely on calculating the difference of
 * framesDecoded across multiple getStats invocations.
 * See https://bugs.webkit.org/show_bug.cgi?id=172682
 * In addition, we need to calculate it in Chrome v58+ and chromium based browsers
 * since framesPerSecond is not implemented in the standard getStats API.
 * In the case of Firefox 96+, the field 'frameRateMean' was removed so we need
 * to use the frame watchet to get the frameRate.
 */
export default () =>
  env.isSafari || env.isChromiumEdge || env.isOpera ||
  (env.name === 'Chrome' && env.version >= 58) || (env.isFirefox && env.version >= 96);
