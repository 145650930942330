import { VideoBackgroundRendererInterface } from "../../interfaces/renderers/video-background";
import { BackgroundVideoPipeline } from "../pipelines/background-video";
import { WebglRenderer } from "./webgl-renderer";

export class WebglVideoBackgroundRenderer
    extends WebglRenderer
    implements VideoBackgroundRendererInterface
{
    protected pipeline: BackgroundVideoPipeline;

    constructor() {
        super();
        this.pipeline = new BackgroundVideoPipeline(this.context);
    }
    public setBackgroundVideo(video: ReadableStreamDefaultReader<VideoFrame>) {
        this.pipeline.reader = video;
    }
}
