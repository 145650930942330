import once from 'lodash/once';
import createLoggerDefault from '../helpers/log';
import Analytics from '../helpers/analytics';
import hasOpenTokSupportHelpers from '../helpers/hasOpenTokSupport';
import isWebSocketSupported from '../helpers/isWebSocketSupported';
import ModalDefault from '../helpers/modal';
import APIKEY from './api_key';
import EnvironmentLoader from '../ot/environment_loader';
import OTHelpers from '../common-js-helpers/OTHelpers';
import userAgentParsingOnce from './user-agent-parsing-logger';
import upgradeHtml from '../../html/upgrade.html';
import isSupportedFactory from '../helpers/isSupported';

export default (deps = {}) => {
  const env = deps.env || OTHelpers.env;
  const Modal = deps.Modal || ModalDefault;
  const createLogger = deps.createLogger || createLoggerDefault;

  const logging = createLogger('systemRequirements');
  const hasOpenTokSupport = hasOpenTokSupportHelpers.once;

  // This will be logged before we even get the correct loggingUrl from
  // config.opentok.com.
  const analytics = new Analytics();

  const logOnce = once(() => {
    analytics.logEvent({
      action: 'checkSystemRequirements',
      variation: 'notHasRequirements',
      partnerId: APIKEY.value,
      payload: { userAgent: env.userAgent },
    });
  });

  const logUserAgentParsingOnce = once(() => {
    userAgentParsingOnce();
  });

  let isDialogOpen = false;
  const browserName = env.name;
  const browserVersion = env.version;
  const isMobile = env.isMobileDevice;
  const {
    isSupportedButOld: isSupportedButOldDefault,
    isBrowserAndVersionSupported: isBrowserAndVersionSupportedDefault,
  } = isSupportedFactory(browserName, browserVersion);

  return {
    /**
    * Checks if the system supports Vonage Video API for WebRTC. Note that this method is called
    * automatically when you call <code>OT.initPublisher()</code> or <code>OT.initSession()</code>,
    * and if the system doesn't support Vonage Video API, the OpenTok.js library displays a message to
    * the user. Call the <code>OT.checkSystemRequirements()</code> method before calling
    * <code>OT.initPublisher()</code> or <code>OT.initSession()</code> if you do not want the
    * library to display that message.
    *
    * @return {Number} Whether the system supports Vonage Video API for WebRTC (1) or not (0).
    * @see <a href="#upgradeSystemRequirements">OT.upgradeSystemRequirements()</a>
    * @method OT.checkSystemRequirements
    * @memberof OT
    */
    check() {
      logging.debug('OT.checkSystemRequirements()');

      // We use Number here to coerce a Boolean to 1 or 0
      const systemRequirementsMet = Number(isWebSocketSupported && hasOpenTokSupport());

      logUserAgentParsingOnce();
      if (!systemRequirementsMet) {
        logOnce();
      }

      return systemRequirementsMet;
    },
    /**
    * Displays information about system requirements for Vonage Video API for WebRTC. This
    * information is displayed in an iframe element that fills the browser window.
    * <p>
    * <i>Note:</i> this information is displayed automatically when you call the
    * <code>OT.initSession()</code> or the <code>OT.initPublisher()</code> method
    * if the client does not support Vonage Video API for WebRTC.
    * </p>
    * <p><i>Note:</i> this function will be deprecated in the future.</p>
    * @see <a href="#checkSystemRequirements">OT.checkSystemRequirements()</a>
    * @method OT.upgradeSystemRequirements
    * @memberof OT
    */
    upgrade({
      // @todo isSupportedButOld relies upon logic outside of itself
      // basically a poor assumption is made here that if we are being called, and
      // our browser is in staticConfig.minimumVersion we must be outdated.
      // The upgrade method should gather this information itself, instead of
      // relying upon assumptions.
      isSupportedButOld = isSupportedButOldDefault(),
      isBrowserAndVersionSupported = isBrowserAndVersionSupportedDefault(),
    } = {}) {
      // trigger after the OT environment has loaded
      EnvironmentLoader.onLoad(() => {
        logging.warn('OT.upgradeSystemRequirements is deprecated.');
        if (isDialogOpen) {
          return;
        }

        const modal = new Modal(upgradeHtml);
        isDialogOpen = true;

        modal.on('close', () => {
          isDialogOpen = false;
        });

        modal.open();

        let section = isMobile ? 'mobile-' : '';

        if (isBrowserAndVersionSupported) {
          section += 'supported-install';
        } else if (isSupportedButOld) {
          section += 'upgrade-install';
        } else {
          section += 'unsupported-install';
        }

        modal.el(`#section-${section}`).style.display = 'block';
      });
    },
  };
};
