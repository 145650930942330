import {
    EventDataMap,
    MediaProcessorConnector,
    MediaProcessorConnectorInterface,
    WarningType,
    ErrorFunction,
    PipelineInfoData,
} from "@vonage/media-processor";
import {
    BlurRadius,
    BackgroundBlurConfig,
    VirtualBackgroundConfig,
    AssetBackgroundConfig,
    VideoBackgroundConfig,
    MediaProcessorBaseConfig,
    SilhouetteBlurConfig,
    MediapipeConfig,
    MediaPipeResults,
    MediaPipeResultsListener,
    MediaPipeModelType,
    FaceDetectionResults,
    FaceDetectionOptions,
    FaceMeshResults,
    FaceMeshOptions,
    HandsResults,
    HandsOptions,
    HolisticResults,
    HolisticOptions,
    ObjectronResults,
    ObjectronOptions,
    PoseResults,
    PoseOptions,
    SelfieSegmentationResults,
    SelfieSegmentationOptions,
    MediaPipeModelConfig,
    SupportedBrowserFeatures,
    FeatureSupport,
    WebglSelfieSegmentationType,
    BackgroundOptions,
    BackgroundTransformerType,
    RenderingOptions,
    RenderingType,
} from "./src/types";
import { isSupported, getSupportedBrowserFeatures } from "./src/utils/support";
import Emittery from "emittery";
import { MediapipeHelper } from "./src/utils/mediapipe";
import { VonageMediaProcessor } from "./src/facades/vonage-media-processor";
import { BackgroundTransformer } from "./src/transformers/background-transformer";

//@ts-ignore
import VonageSelfieSegmentation from "./libs/selfie-segmentation-helper";
/**
 * MediaPipe Selfie Segmentation static metadata.
 * @returns
 * An object containing MediaPipe Selfie Segmentation metadata:
 * - VERSION
 */
function getVonageSelfieSegmentation(): VonageSelfieSegmentation {
    return VonageSelfieSegmentation;
}

//@ts-ignore
import VonageHands from "./libs/hands-helper";
/**
 * Vonage Mediapipe objects info (it will be used by the @mediapipe/drawing_utils package which is not included by this library).
 * @param VonageSelfiesegmentation:
 * - VERSION
 * @param VonageHands:
 * - VERSION
 * - HAND_CONNECTIONS
 */
function getVonageHands(): VonageHands {
    return VonageHands;
}

//@ts-ignore
import VonageHolistic from "./libs/holistic-helper";
/**
 * MediaPipe Holistic static metadata.
 * This metadata is needed while using MediaPipe.
 * [drawing utils](https://www.npmjs.com/package/@mediapipe/drawing_utils).
 * And [control utils](https://www.npmjs.com/package/@mediapipe/control_utils).
 * @returns
 * An object containing MediaPipe Holistic metadata:
 * - VERSION
 * - FACE_GEOMETRY
 * - FACEMESH_LIPS
 * - FACEMESH_LEFT_EYE
 * - FACEMESH_LEFT_EYEBROW
 * - FACEMESH_LEFT_IRIS
 * - FACEMESH_RIGHT_EYE
 * - FACEMESH_RIGHT_EYEBROW
 * - FACEMESH_RIGHT_IRIS
 * - FACEMESH_FACE_OVAL
 * - FACEMESH_CONTOURS
 * - FACEMESH_TESSELATION
 * - HAND_CONNECTIONS
 * - POSE_CONNECTIONS
 * - POSE_LANDMARKS
 * - POSE_LANDMARKS_LEFT
 * - POSE_LANDMARKS_RIGHT
 * - POSE_LANDMARKS_NEUTRAL
 */
function getVonageHolistic(): VonageHolistic {
    return VonageHolistic;
}

//@ts-ignore
import VonageFaceDetection from "./libs/face-detection-helper";
/**
 * MediaPipe Face Detection static metadata.
 * This metadata is needed while using MediaPipe.
 * [drawing utils](https://www.npmjs.com/package/@mediapipe/drawing_utils).
 * And [control utils](https://www.npmjs.com/package/@mediapipe/control_utils).
 * @returns
 *  An object containing MediaPipe Face Detection metadata:
 * - VERSION
 * - FACEDETECTION_LIPS
 * - FACEDETECTION_LEFT_EYE
 * - FACEDETECTION_LEFT_EYEBROW
 * - FACEDETECTION_RIGHT_EYE
 * - FACEDETECTION_RIGHT_EYEBROW
 * - FACEDETECTION_FACE_OVAL
 * - FACEDETECTION_CONTOURS
 * - FACEDETECTION_TESSELATION
 */
function getVonageFaceDetection(): VonageFaceDetection {
    return VonageFaceDetection;
}
//@ts-ignore
import VonageFaceMash from "./libs/face-mash-helper";
/**
 * MediaPipe Face Mash static metadata.
 * This metadata is needed while using MediaPipe.
 * [drawing utils](https://www.npmjs.com/package/@mediapipe/drawing_utils).
 * And [control utils](https://www.npmjs.com/package/@mediapipe/control_utils).
 * @returns
 * An object containing MediaPipe Face Mash metadata:
 * - VERSION
 * - FACE_GEOMETRY
 * - FACEMESH_LIPS
 * - FACEMESH_LEFT_EYE
 * - FACEMESH_LEFT_EYEBROW
 * - FACEMESH_LEFT_IRIS
 * - FACEMESH_RIGHT_EYE
 * - FACEMESH_RIGHT_EYEBROW
 * - FACEMESH_RIGHT_IRIS
 * - FACEMESH_FACE_OVAL
 * - FACEMESH_CONTOURS
 * - FACEMESH_TESSELATION
 */
function getVonageFaceMash(): VonageFaceMash {
    return VonageFaceMash;
}

//@ts-ignore
import VonageObjectron from "./libs/objectron-helper";
/**
 * MediaPipe Objectron static metadata.
 * This metadata is needed while using MediaPipe.
 * [drawing utils](https://www.npmjs.com/package/@mediapipe/drawing_utils).
 * And [control utils](https://www.npmjs.com/package/@mediapipe/control_utils).
 * @returns
 * An object containing MediaPipe Objectron metadata:
 * - VERSION
 * - VERSION
 * - BOX_CONNECTIONS
 * - BOX_KEYPOINTS
 */
function getVonageObjectron(): VonageObjectron {
    return VonageObjectron;
}

//@ts-ignore
import VonagePose from "./libs/pose-helper";
/**
 * MediaPipe Pose static metadata.
 * This metadata is needed while using MediaPipe.
 * [drawing utils](https://www.npmjs.com/package/@mediapipe/drawing_utils).
 * And [control utils](https://www.npmjs.com/package/@mediapipe/control_utils).
 * @returns
 * An object containing MediaPipe Pose metadata:
 * - VERSION
 * - POSE_CONNECTIONS
 * - POSE_LANDMARKS
 * - POSE_LANDMARKS_LEFT
 * - POSE_LANDMARKS_RIGHT
 * - POSE_LANDMARKS_NEUTRAL
 */
function getVonagePose(): VonagePose {
    return VonagePose;
}

/**
 * MediaProcessorConfig specifies the transformer logic to be performed.
 */
type MediaProcessorConfig = BackgroundOptions;

/**
 * builder function to create MediaProcessor
 * @param config - see `MediaProcessorConfig` definition
 * @returns - `MediaProcessor` provide interface to configure ml-transformer or retrieving connector
 */
export async function createVonageMediaProcessor(
    config: MediaProcessorConfig
): Promise<VonageMediaProcessor> {
    return await VonageMediaProcessor.create(config);
}

export type {
    EventDataMap,
    WarnData,
    ErrorData,
    EventMetaData,
    DropInfo,
} from "@vonage/media-processor";

export type {
    MediaProcessorBaseConfig,
    BackgroundBlurConfig,
    VirtualBackgroundConfig,
    VideoBackgroundConfig,
    AssetBackgroundConfig,
    SilhouetteBlurConfig,
    MediaProcessorConfig,
    BackgroundOptions,
    MediapipeConfig,
    MediaPipeResults,
    MediaPipeModelType,
    MediaPipeResultsListener,
    FaceDetectionResults,
    FaceDetectionOptions,
    FaceMeshResults,
    FaceMeshOptions,
    HandsResults,
    HandsOptions,
    HolisticResults,
    HolisticOptions,
    ObjectronResults,
    ObjectronOptions,
    SelfieSegmentationOptions,
    SelfieSegmentationResults,
    PoseResults,
    PoseOptions,
    MediaPipeModelConfig,
    SupportedBrowserFeatures,
    FeatureSupport,
    RenderingOptions,
};

export {
    VonageMediaProcessor,
    BlurRadius,
    isSupported,
    getSupportedBrowserFeatures,
    MediapipeHelper,
    getVonageSelfieSegmentation,
    getVonageFaceDetection,
    getVonageFaceMash,
    getVonageHands,
    getVonageHolistic,
    getVonageObjectron,
    getVonagePose,
    WarningType,
    PipelineInfoData,
    ErrorFunction,
    BackgroundTransformer,
    WebglSelfieSegmentationType,
    BackgroundTransformerType,
    RenderingType,
};
