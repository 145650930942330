import { TextureSource, vec4 } from "../types";

import * as twgl from "twgl.js";

/**
 * Render a VideoFrame within a canvas
 * @param frame VideoFrame to render
 * @param context Canvas context to render in
 */
export function frameToCanvas(
    frame: VideoFrame,
    context: OffscreenCanvasRenderingContext2D
) {
    context.canvas.width = frame.displayWidth;
    context.canvas.height = frame.displayHeight;
    context.beginPath();
    context.drawImage(frame, 0, 0, frame.displayWidth, frame.displayHeight);
}

/**
 * Create and fill a webgl texture
 * If source is a texture, it will just be returned without creating a new texture
 * @param context Webgl context to create the texture on
 * @param source Source used to fill the texture
 * @returns Created texture, or source if it's a WebGLTexture
 */
export function texture(
    context: WebGLRenderingContext,
    source?: TextureSource | WebGLTexture
): () => WebGLTexture {
    return () =>
        source instanceof WebGLTexture
            ? source
            : twgl.createTexture(context, {
                  src: source,
                  wrap: context.CLAMP_TO_EDGE,
              });
}

/**
 * Create a webgl texture and fill it with a color
 * @param context Webgl context to create the texture on
 * @param width Width of the texture
 * @param height Height of the texture
 * @param color Color to fill the texture with
 * @returns Created texture
 */
export function textureWithColor(
    context: WebGLRenderingContext,
    width: number,
    height: number,
    color: vec4
): () => WebGLTexture {
    return () => twgl.createTexture(context, { color, width, height });
}

/**
 * Minimum size for a webgl texture
 */
export const WEBGL_MINIMUM_TEXTURE_SIZE: number = 2;
