class VonageAssetsLoaderHelper {
    static async createVideoReadable(
        videoUri: string
    ): Promise<ReadableStream> {
        return new Promise<ReadableStream>((resolve, reject) => {
            const bgvideo: HTMLVideoElement = document.createElement("video");
            let errorAbortSignal: AbortController = new AbortController();
            let canplayAbortSignal: AbortController = new AbortController();
            bgvideo.addEventListener(
                "canplay",
                () => {
                    errorAbortSignal.abort();
                    bgvideo.muted = true;
                    bgvideo.loop = true;
                    bgvideo.play().then(() => {
                        try {
                            // @ts-ignore
                            const bgMediaStream: MediaStream = (
                                bgvideo as any
                            ).captureStream();
                            // @ts-ignore
                            const processor: any =
                                new MediaStreamTrackProcessor(
                                    (bgMediaStream as any).getVideoTracks()[0]
                                );
                            resolve(processor.readable);
                        } catch (e) {
                            reject(e);
                        }
                    });
                },
                { once: true, signal: canplayAbortSignal.signal }
            );
            bgvideo.addEventListener(
                "error",
                (e) => {
                    canplayAbortSignal.abort();
                    reject("loading error:" + e);
                },
                { once: true, signal: errorAbortSignal.signal }
            );
            bgvideo.crossOrigin = "anonymous";
            bgvideo.src = videoUri;
        });
    }

    static async createImageCanvas(imageUri: string): Promise<ImageBitmap> {
        const response = await fetch(imageUri);
        const blob = await response.blob();
        return await createImageBitmap(blob);
    }
}
export default VonageAssetsLoaderHelper;
