import { WebglPipelineProgram } from "../../webgl-pipeline-program";
import fragmentShader from "./mix.frag?raw";
import vertexShader from "./mix.vert?raw";

export class Mix extends WebglPipelineProgram {
    protected getFragmentShader(): string {
        return fragmentShader;
    }
    protected getVertexShader(): string {
        return vertexShader;
    }
}
