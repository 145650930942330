// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-use-before-define */

export default function listenForTracksEndedFactory() {
  function isEveryTrackEnded(stream) {
    return stream.getTracks().every(track => track.readyState === 'ended');
  }

  return function listenForTracksEnded(stream, onAllEnded, onSingleEnded) {
    const tracks = [];

    function singleTrackEndedHandler(event) {
      // We wait a fraction of a second for all ended events to be emitted before
      // determining if all tracks have ended or just a subset of them.
      setTimeout(() => {
        if (!tracks.length) {
          return;
        }

        if (isEveryTrackEnded(stream)) {
          removeAllTrackListeners();
          if (typeof onAllEnded === 'function') {
            onAllEnded();
          }
        } else {
          const track = event.target;
          removeTrackListener(track);
          if (typeof onSingleEnded === 'function') {
            onSingleEnded(track);
          }
        }
      }, 100);
    }

    function removeTrackListener(track) {
      track.onended = null; // eslint-disable-line no-param-reassign
      const index = tracks.indexOf(track);
      if (index >= 0) {
        tracks.splice(index, 1);
      }
    }

    function removeAllTrackListeners() {
      tracks.slice().forEach(removeTrackListener);
    }

    function addTrackListeners() {
      stream.getTracks().forEach((track) => {
        tracks.push(track);
        const originalOnEnded = track.onended;
        // eslint-disable-next-line no-param-reassign
        track.onended = (...args) => {
          if (originalOnEnded) {
            // When self-subscribing to a published stream, both the publisher and self-subscriber
            // are referencing the same object. After a subscriber is created, the onended listener
            // for the publisher will be overwritten unless we store it. See VIDCS-3131.
            originalOnEnded(...args);
          }
          singleTrackEndedHandler(...args);
        };
      });
    }
    addTrackListeners();

    return {
      stop: removeAllTrackListeners,
      refresh: () => {
        removeAllTrackListeners();
        addTrackListeners();
      },
    };
  };
}
