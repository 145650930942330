import { score } from 'rtcscore';
import EventsFactory from '../ot/events';
import SDPHelpers from '../ot/peer_connection/sdp_helpers';

const Events = EventsFactory();

const calculateBitrate = (currentStats, lastStats) => {
  const timeDeltaInSeconds = (currentStats.timestamp - lastStats.timestamp) / 1000;
  return ((currentStats.bytesReceived - lastStats.bytesReceived) / timeDeltaInSeconds) * 8;
};

// See https://www.w3.org/TR/webrtc-stats/#dom-rtcinboundrtpstreamstats-jitterbufferdelay
const calculateJitterBufferMovingAverage = (currentStats, lastStats) => {
  const jitterBufferEmittedCountDelta = currentStats.jitterBufferEmittedCount
    - lastStats.jitterBufferEmittedCount;
  const jitterBufferDelayDelta = currentStats.jitterBufferDelay - lastStats.jitterBufferDelay;
  return jitterBufferDelayDelta / jitterBufferEmittedCountDelta;
};

// This can return NaN if packetsLost and packetsReceived are both zero
const calculatePacketsLostPercentage = currentStats => (currentStats.packetsLost /
    (currentStats.packetsLost + currentStats.packetsReceived)) * 100;

const deleteUndefinedProps = (statObject) => {
  Object.keys(statObject).forEach((key) => {
    if (statObject[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete statObject[key];
    }
  });
  return statObject;
};
const getVideoCodecFromStat = (stat) => {
  const mediaTypeAndCodec = stat.mimeType.split('/');
  const mediaType = mediaTypeAndCodec[0];
  const codec = mediaTypeAndCodec[1].toLowerCase();
  if (mediaType === 'video') {
    return codec;
  }
  return undefined;
};

const createMOSScoreHelper = () => ({
  // samplingInterval is modified by unit tests
  startMonitoringInterval(properties) {
    const { getStats, subscriber, getCurrentPeerConnection, logging, qosQualityScores,
      rtcScore = score, samplingInterval = 2000 } = properties;

    let lastVideoStats;
    let lastAudioStats;
    let getStatsInterval;

    let previousAudioScore;
    let previousVideoScore;

    const getStatsCallback = async (error, stats) => {
      if (error) {
        clearInterval(getStatsInterval);
        logging.warn(`getStats failed with "${error}"`);
        return;
      }
      const peerConnection = await getCurrentPeerConnection();
      const dtxEnabled = SDPHelpers.hasSendDtx(peerConnection.remoteDescription().sdp);
      const fecEnabled = SDPHelpers.hasOpusFec(peerConnection.remoteDescription().sdp);

      const rtcStats = {
        audio: {
          packetLoss: undefined,
          bitrate: undefined,
          roundTripTime: undefined,
          bufferDelay: undefined,
          fec: fecEnabled,
          dtx: dtxEnabled,
        },
        video: {
          packetLoss: undefined,
          bitrate: undefined,
          roundTripTime: undefined,
          bufferDelay: undefined,
          codec: undefined,
          width: undefined,
          height: undefined,
          expectedWidth: undefined,
          expectedHeight: undefined,
          frameRate: undefined,
          expectedFrameRate: undefined,
        },
      };

      stats.forEach((stat) => {
        if (stat.type === 'codec') {
          const videoCodec = getVideoCodecFromStat(stat);
          if (videoCodec) {
            rtcStats.video.codec = videoCodec;
          }
        }
        if (stat.kind === 'audio') {
          if (stat.type === 'remote-outbound-rtp') {
            rtcStats.audio.roundTripTime = stat.totalRoundTripTime;
          }
          if (stat.type === 'inbound-rtp') {
            const currAudioStats = stat;

            rtcStats.audio.packetLoss = calculatePacketsLostPercentage(currAudioStats);
            if (lastAudioStats) {
              rtcStats.audio.bitrate = calculateBitrate(currAudioStats, lastAudioStats);
              rtcStats.audio.bufferDelay =
                    calculateJitterBufferMovingAverage(currAudioStats, lastAudioStats);
            }

            lastAudioStats = currAudioStats;
          }
        }

        if (stat.kind === 'video') {
          // RTT returns 0 in most cases for subscriber. We still keep this logic for thoroughness.
          if (stat.type === 'remote-outbound-rtp') {
            rtcStats.video.roundTripTime = stat.totalRoundTripTime;
          }
          if (stat.type === 'inbound-rtp') {
            const currVideoStats = stat;

            rtcStats.video.packetLoss = calculatePacketsLostPercentage(currVideoStats);
            if (lastVideoStats) {
              rtcStats.video.bitrate = calculateBitrate(currVideoStats, lastVideoStats);
              rtcStats.video.bufferDelay =
                  calculateJitterBufferMovingAverage(currVideoStats, lastVideoStats);
            }

            const actualDimensions = subscriber.stream?.videoDimensions;
            if (actualDimensions) {
              rtcStats.video.width = actualDimensions?.width;
              rtcStats.video.height = actualDimensions?.height;
            }
            const preferredDimensions = subscriber?._preferredResolution;
            if (preferredDimensions) {
              rtcStats.video.expectedWidth = subscriber?._preferredResolution.width;
              rtcStats.video.expectedHeight = subscriber?._preferredResolution.height;
            }
            rtcStats.video.frameRate = currVideoStats.framesPerSecond;
            rtcStats.video.expectedFrameRate = subscriber?._preferredFrameRate;
            lastVideoStats = currVideoStats;
          }
        }
      });

      rtcStats.video = deleteUndefinedProps(rtcStats.video);
      rtcStats.audio = deleteUndefinedProps(rtcStats.audio);
      const scores = rtcScore(rtcStats);

      const qualityScore = {};
      const currentAudioScore = Number.isNaN(scores.audio) ? undefined : scores.audio;
      const currentVideoScore = Number.isNaN(scores.video) ? undefined : scores.video;
      if (qosQualityScores) {
        qosQualityScores.audioQualityScore = currentAudioScore;
        qosQualityScores.videoQualityScore = currentVideoScore;
      }
      if (scores.audio && subscriber.stream.hasAudio) {
        qualityScore.audioQualityScore = scores.audio;
      }
      if (scores.video && subscriber.stream.hasVideo) {
        qualityScore.videoQualityScore = scores.video;
      }
      if (previousVideoScore !== currentVideoScore || previousAudioScore !== currentAudioScore) {
        // Don't emit an empty object
        if (Object.keys(qualityScore).length > 0) {
          subscriber.dispatchEvent(new Events.QualityScoreChangedEvent(qualityScore));
        }
      }
      previousAudioScore = currentAudioScore;
      previousVideoScore = currentVideoScore;
    };
    getStatsInterval = setInterval(() => { getStats(getStatsCallback); }, samplingInterval);

    subscriber.on('destroyed', () => {
      clearInterval(getStatsInterval);
    });
  },
});
export default createMOSScoreHelper;
