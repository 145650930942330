import { UniformDataMap } from "../../types";
import { WebglProfiler } from "./webgl-profiler";
import { WebglPipelineProgram } from "./webgl-pipeline-program";

/**
 * Program pipeline run infos
 */
export interface WebglPipelineStep {
    /**
     * Pipeline program to run
     */
    program: WebglPipelineProgram;

    /**
     * Function returning the uniform to bind to the program
     * @returns Object containing for each uniform a pair key/value name/value
     */
    getUniforms: () => UniformDataMap;

    /**
     * If set to true, the step won't be rescale if the output is resized
     */
    preventResize?: boolean;
}

/**
 * Webgl pipeline. Run pipeline program one after one
 */
export class WebglPipeline {
    /**
     * Pipeline program infos to run
     */
    private steps: WebglPipelineStep[] = [];

    /**
     * Add a program pipeline to the rendering pipeline
     * @param step Program pipeline infos
     */
    public addStep(step: WebglPipelineStep) {
        this.steps.push(step);
    }

    /**
     * Run the pipeline
     */
    public async run() {
        for (const step of this.steps) {
            step.program.run(step.getUniforms());
        }
    }

    public resizeOutput(width: number, height: number) {
        this.profiler?.pushContext(`PIP [${this.constructor.name}]`);
        for (const step of this.steps) {
            if (!step.preventResize) {
                step.program.resizeOutput(width, height);
            }
        }
        this.profiler?.popContext(`PIP [${this.constructor.name}]`);
    }

    private profiler?: WebglProfiler;
    public setProfiler(profiler?: WebglProfiler) {
        this.profiler = profiler;
        for (const step of this.steps) {
            step.program.setProfiler(profiler);
        }
    }

    public setId(id: string) {
        for (const step of this.steps) {
            step.program.id = id;
        }
    }
}
