import { BlurBackgroundRendererInterface } from "../../interfaces/renderers/blur-background";
import { Canvas2dRenderer } from "./canvas2d-renderer";

export class Canvas2dBackgroundRenderer
    extends Canvas2dRenderer
    implements BlurBackgroundRendererInterface
{
    constructor(private radius: number) {
        super();
    }

    public async render(
        image: ImageBitmap,
        mask: ImageData
    ): Promise<OffscreenCanvas> {
        super.resizeCanvas(image);
        const imageBitmap = await createImageBitmap(mask);
        this.renderSilhouette(image, imageBitmap);

        this.context.save();
        this.context.filter = `blur(${this.radius}px)`;
        this.context.globalCompositeOperation = "destination-over";
        this.context.drawImage(image, 0, 0, image.width, image.height);
        this.context.restore();

        return super.render(image, mask);
    }
}
