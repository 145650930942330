import { ResolvedWebglQuery, WebglQueryAction } from "./webgl-profiler";

/**
 * @internal
 */
export class WebglProfilerReporter {
    constructor(private queries: ResolvedWebglQuery[][]) {}

    public async downloadSpeedscope(): Promise<void> {
        this.download(await this.convertToSpeedscope());
    }

    private download(content: string) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(
            new Blob([content], { type: "application/json" })
        );
        link.download = `profile-${+new Date()}.speedscope.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private convertToSpeedscope(): string {
        const frames: any[] = [];
        const nameToFrame: any = {};
        const profiles: any = [];

        let last = 0;
        for (const profile of this.queries) {
            const events: any[] = [];
            for (const query of profile) {
                if (nameToFrame[query.name] === undefined) {
                    nameToFrame[query.name] = frames.length;
                    frames.push({
                        name: query.name,
                    });
                }
                events.push({
                    type: query.action == WebglQueryAction.PUSH ? "O" : "C",
                    frame: nameToFrame[query.name],
                    at: query.timestamp,
                });
                last = Math.max(last, query.timestamp + query.duration);
            }

            profiles.push({
                events,
                type: "evented",
                name: "GPU Profile",
                unit: "nanoseconds",
                startValue: 0,
                endValue: last,
            });
        }

        const file = {
            $schema: "https://www.speedscope.app/file-format-schema.json",
            shared: {
                frames,
            },
            profiles,
        };

        return JSON.stringify(file, null, 2);
    }
}
