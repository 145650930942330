import { BlurSilhouetteRendererInterface } from "../../interfaces/renderers/blur-silhouette";
import { ForegroundBlurPipeline } from "../pipelines/foreground-blur";
import { WebglRenderer } from "./webgl-renderer";

export class WebglBlurSilhouetteRenderer
    extends WebglRenderer
    implements BlurSilhouetteRendererInterface
{
    protected pipeline: ForegroundBlurPipeline;

    constructor(radius: number) {
        super();
        this.pipeline = new ForegroundBlurPipeline(this.context, radius);
        this.disablePostProcessing();
    }
}
