import { RendererInterface } from "../../interfaces/renderer";
import { FlickeringOptions } from "../../webgl/pipelines/improve-segmentation-mask";
import { ResolvedWebglQuery } from "../../webgl/webgl-profiler";

export abstract class Canvas2dRenderer implements RendererInterface {
    protected canvas: OffscreenCanvas;
    protected context: OffscreenCanvasRenderingContext2D;

    constructor() {
        const canvas = new OffscreenCanvas(0, 0);
        const context = canvas.getContext("2d", { willReadFrequently: true });

        if (!context) {
            throw "Fail to retrieve 2d context";
        }

        this.canvas = canvas;
        this.context = context;
    }

    public setFlickeringOptions(options: FlickeringOptions): void {}

    public enablePostProcessing(): void {}
    public disablePostProcessing(): void {}

    public async profileWebgl(duration: number): Promise<ResolvedWebglQuery[]> {
        return [];
    }

    public async render(
        image: ImageBitmap,
        mask: ImageData
    ): Promise<OffscreenCanvas> {
        return this.canvas;
    }

    protected resizeCanvas(image: ImageBitmap) {
        const { width, height } = image;
        this.canvas.width = width;
        this.canvas.height = height;
    }

    protected renderSilhouette(
        image: ImageBitmap,
        mask: ImageBitmap,
        filter: string = "none"
    ) {
        const { width, height } = image;
        this.context.save();
        this.context.drawImage(
            mask,
            0,
            0,
            mask.width,
            mask.height,
            0,
            0,
            width,
            height
        );

	// Note: Temporary mask blur step.
        this.context!.filter = "blur(5px)";
        this.context!.globalCompositeOperation = "source-in";
        this.context.drawImage(
            mask,
            0,
            0,
            mask.width,
            mask.height,
            0,
            0,
            width,
            height
        );

        this.context.globalCompositeOperation = "source-in";
        this.context.filter = filter;
        this.context.drawImage(
            image,
            0,
            0,
            width,
            height,
            0,
            0,
            this.canvas.width,
            this.canvas.height
        );
        this.context.restore();
    }

    destroy() {}
}
