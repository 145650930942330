import {
    WebglPipelineProgram,
    WebglPipelineProgramOptions,
} from "../../webgl-pipeline-program";
import fragmentShader from "./blur.frag?raw";
import vertexShader from "./blur.vert?raw";

export enum BLUR_TYPE {
    LINEAR,
    GAUSSIAN,
}
export interface BlurOptions extends WebglPipelineProgramOptions {
    radius?: number;
    type?: BLUR_TYPE;
}
export class Blur extends WebglPipelineProgram<BlurOptions> {
    protected getDefines(): { [key: string]: number } {
        return { RADIUS: this.options.radius ?? 1 };
    }
    protected getFragmentShader(): string {
        return fragmentShader + this.createLoopFunction();
    }

    protected getVertexShader(): string {
        return vertexShader;
    }

    private createLoopFunction(): string {
        let radius = this.options.radius ?? 1;
        return `

        vec4 loop() { 
            vec4 result = vec4(0,0,0,0);
            float factorSum = 0.;

            vec2 normalizedRadius = vec2(-${radius}.) / canvas;
            vec2 normalizedIncrement = vec2(1.) / canvas;
            vec2 diff = normalizedRadius;

            for(int y=-${radius}; y<${radius + 1}; ++y) {
                for(int x=-${radius}; x<${radius + 1}; ++x) {
                    float factor = 1.;
                    result += factor * texture2D(texture, _texture_coord + diff);
                    factorSum += factor;
                    diff.x += normalizedIncrement.x;
                }
                diff.y += normalizedIncrement.y;
                diff.x = normalizedRadius.x;
            }
            return result / factorSum;
        }`;
    }

    // https://en.wikipedia.org/wiki/Gaussian_blur
    private getFactor(x: number, y: number): number {
        switch (this.options.type) {
            case BLUR_TYPE.GAUSSIAN:
                return this.getLinearFactor(x, y);
            case BLUR_TYPE.LINEAR:
            default:
                return this.getLinearFactor(x, y);
        }
    }

    // https://en.wikipedia.org/wiki/Gaussian_blur
    private getLinearFactor(x: number, y: number): number {
        return 1;
    }

    // https://en.wikipedia.org/wiki/Gaussian_blur
    private getGaussianFactor(x: number, y: number): number {
        const TAU = Math.PI * 2;
        const variance = Math.max(this.options.radius ?? 1, 1);

        const eFactor = 1 / (TAU * variance ** 2);
        const ePowerNumerator = -(x ** 2 + y ** 2);
        const ePower = eFactor * ePowerNumerator;
        return eFactor * Math.E ** ePower;
    }
}
