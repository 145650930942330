import { TextureSource } from "../../../types";
import { ImageBackgroundRendererInterface } from "../../interfaces/renderers/image-background";
import { BackgroundImagePipeline } from "../pipelines/background-image";
import { ResizeImagePipeline } from "../pipelines/resize-image";
import { WebglRenderer } from "./webgl-renderer";

export class WebglImageBackgroundRenderer
    extends WebglRenderer
    implements ImageBackgroundRendererInterface
{
    protected pipeline: BackgroundImagePipeline;

    private backgroundImage?: ImageBitmap;
    private backgroundImageResizerCanvas: OffscreenCanvas;
    private backgroundImageResizerContext: WebGL2RenderingContext;
    private backgroundImageResizer: ResizeImagePipeline;

    constructor() {
        super();

        this.pipeline = new BackgroundImagePipeline(this.context);

        const canvas = new OffscreenCanvas(0, 0);
        const context = canvas.getContext("webgl2");

        if (!context) {
            throw "Fail to retrieve webgl context";
        }

        this.backgroundImageResizerCanvas = canvas;
        this.backgroundImageResizerContext = context;
        this.backgroundImageResizer = new ResizeImagePipeline(
            this.backgroundImageResizerContext,
            0,
            0
        );
    }

    public setBackgroundImage(image: ImageBitmap): void {
        this.backgroundImage = image;
        this.updateBackgroundImage();
    }

    private updateBackgroundImage() {
        const { width, height } = this.canvas;
        this.backgroundImageResizer.resizeOutput(width, height);
        this.backgroundImageResizer.setData(this.backgroundImage);
        this.backgroundImageResizer.run();
        this.pipeline.inputBackgroundImage = this.backgroundImageResizerCanvas;
    }

    protected resizeOutput(width: number, height: number) {
        super.resizeOutput(width, height);
        this.backgroundImageResizerCanvas.width = width;
        this.backgroundImageResizerCanvas.height = height;
        this.backgroundImageResizer.resizeOutput(width, height);
        this.updateBackgroundImage();
    }

    destroy() {
        const ext = this.backgroundImageResizerContext?.getExtension('WEBGL_lose_context')
        if (ext) {
            ext.loseContext();
        }
        super.destroy();
    }
}
