import eventNames from './eventNames';
import ExceptionCodes from '../ot/exception_codes';
import EventsFactory from '../ot/events';

const Events = EventsFactory();

export default (device, step) => new Events.ExceptionEvent(
  eventNames.ACCESS_DENIED,
  `${device} permission denied ${step}`,
  'accessDenied',
  ExceptionCodes.UNABLE_TO_PUBLISH
);
