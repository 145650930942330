import { ImageBackgroundRendererInterface } from "../../interfaces/renderers/image-background";
import { Canvas2dRenderer } from "./canvas2d-renderer";

export class Canvas2dImageBackgroundRenderer
    extends Canvas2dRenderer
    implements ImageBackgroundRendererInterface
{
    private backgroundImage?: ImageBitmap;

    public setBackgroundImage(image: ImageBitmap): void {
        this.backgroundImage = image;
    }

    public async render(
        image: ImageBitmap,
        mask: ImageData
    ): Promise<OffscreenCanvas> {
        super.resizeCanvas(image);
	const imageBitmap = await createImageBitmap(mask);
        this.renderSilhouette(image, imageBitmap);

        if (this.backgroundImage) {
            this.context.save();
            this.context.filter = `none`;
            this.context.globalCompositeOperation = "destination-over";
            this.context.drawImage(
                this.backgroundImage,
                0,
                0,
                this.backgroundImage.width,
                this.backgroundImage.height,
                0,
                0,
                image.width,
                image.height
            );
            this.context.restore();
        }

        return super.render(image, mask);
    }
}
