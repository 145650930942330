/* eslint-disable global-require */
import lodashOnce from 'lodash/once';
import hasGetUserMediaCapabilityDefault from './hasGetUserMediaCapability';
import envDefault from '../helpers/env';
import hasPeerConnectionCapabilityDefault from './hasPeerConnectionCapability';

// Indicates whether this client supports WebRTC
//
// This is defined as: getUserMedia + PeerConnection + exceeds min browser version
//
const check = (deps = {}) => {
  const env = deps.env || envDefault;
  const hasGetUserMediaCapability =
    deps.hasGetUserMediaCapability || hasGetUserMediaCapabilityDefault;
  const hasPeerConnectionCapability =
    deps.hasPeerConnectionCapability || hasPeerConnectionCapabilityDefault;

  if (env.name === 'Node') {
    // Node works, even though it doesn't have getUserMedia
    return true;
  }

  return hasGetUserMediaCapability() && hasPeerConnectionCapability();
};

const once = lodashOnce(() => check());

export default {
  check,
  once,
};
