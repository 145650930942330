import { RendererFactoryInterface } from "../../interfaces/factory";
import { Canvas2dBackgroundRenderer } from "./blur-background";
import { Canvas2dBlurSilhouetteRenderer } from "./blur-silhouette";
import { Canvas2dImageBackgroundRenderer } from "./image-background";
import { Canvas2dVideoBackgroundRenderer } from "./video-background";

export class Canvas2dRendererFactory implements RendererFactoryInterface {
    public blurBackground(radius: number): Canvas2dBackgroundRenderer {
        return new Canvas2dBackgroundRenderer(radius);
    }
    public blurSilhouette(radius: number): Canvas2dBlurSilhouetteRenderer {
        return new Canvas2dBlurSilhouetteRenderer(radius);
    }
    public imageBackground(): Canvas2dImageBackgroundRenderer {
        return new Canvas2dImageBackgroundRenderer();
    }
    public videoBackground(): Canvas2dVideoBackgroundRenderer {
        return new Canvas2dVideoBackgroundRenderer();
    }
}
